<template>
  <div class="public-layout">
    <public-header />
    <main>
      <router-view />
    </main>
    <public-footer />
  </div>
</template>
<script>
import PublicHeader from '@/components/Public/Navigation/Header.vue';
import PublicFooter from '@/components/Public/Navigation/Footer.vue';
export default {
  components: {
    PublicHeader,
    PublicFooter
  },
  methods: {
    setLanguage(lang) {
    },
    onToggleLanguagePanel(event) {
      this.$refs.langmenu.toggle(event);
    }
  }
};
</script>
<style lang="scss">
.public-layout main {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  min-height: calc(100vh - 80px - 334px);
}
</style>