<template>
  <ConfirmPopup></ConfirmPopup>
</template>

<script>
import { usePrimeVue } from 'primevue/config';
import useI18n from '@/plugins/useI18n';
import ConfirmPopup from 'primevue/confirmpopup';

export default {
  components: {
    ConfirmPopup
  },
  setup() {
    const { i18n } = useI18n();
    const primevue = usePrimeVue();

    primevue.config.locale.accept = i18n.$t('Yes');
    primevue.config.locale.reject = i18n.$t('No');
  }
};
</script>