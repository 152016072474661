import axios from '../plugins/axios';
import {
  FETCH_EMPLOYEES,
  SET_EMPLOYEES,
  GET_EMPLOYEES,
  SET_SELECTED_EMPLOYEES,
  GET_SELECTED_EMPLOYEES
} from './types';

const state = {
  employees: [],
  selectedEmployees: []
};

const actions = {
  async [FETCH_EMPLOYEES](context, owner_id) {
    const res = await axios.get(`user/teams/${owner_id}`);
    context.commit(SET_EMPLOYEES, res.data.data.items);
  },

  /*async [FETCH_EMPLOYEES_SERVICES](context, service_id) {
    const res = await axios.get(`service/${service_id}`);
    context.commit(SET_EMPLOYEES, res.data.data.items);
  }*/

};

const mutations = {
  [SET_EMPLOYEES](state, payload) {
    state.employees = payload;
  },
  [SET_SELECTED_EMPLOYEES](state, payload) {
    state.selectedEmployees = payload;
  }
};

const getters = {
  [GET_EMPLOYEES]: (state) => state.employees,
  [GET_SELECTED_EMPLOYEES]: (state) => state.selectedEmployees
};

export default {
  state,
  actions,
  mutations,
  getters
};
