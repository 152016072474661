import axios from '../plugins/axios';

import {
  FETCH_INFORMATIONS,
  SET_INFORMATIONS,
  GET_INFORMATIONS,
  SET_INFORMATION_2_NOTIFICATION,
  GET_INFORMATION_2_NOTIFICATION,
  SET_SELECTED_GROUPS_2_NOTIFICATION,
  GET_SELECTED_GROUPS_2_NOTIFICATION,
  FETCH_INFORMATION_NOTIFICATIONS,
  SET_INFORMATION_NOTIFICATIONS,
  GET_INFORMATION_NOTIFICATIONS
} from './types';

const state = {
  informations: [],
  information2Notification: {},
  selectedGroups: [],
  notifications: []
};

const actions = {
  async [FETCH_INFORMATIONS](context, owner_id) {
    const res = await axios.get(`information?owner_id=${owner_id}`);
    context.commit(SET_INFORMATIONS, res.data.data.items);
  },
  async [FETCH_INFORMATION_NOTIFICATIONS](context, item_id) {
    const res = await axios.get(`notification?item_id=${item_id}`);
    const notifications = [];
    if (res.data.data.items) {
      for (const item of res.data.data.items) {
        try {
          const contact = await axios.get(`contact/${item.participants_id}`);
          const contactData = contact.data.data.items[0];
          notifications.push({
            ...item,
            contact: `${contactData.first_name} ${contactData.last_name}`
          });
        } catch {
          notifications.push({
            ...item,
            contact: 'No Contact'
          });
        }
      }
    }

    context.commit(SET_INFORMATION_NOTIFICATIONS, notifications);
  }
};

const mutations = {
  [SET_INFORMATIONS](state, payload) {
    state.informations = payload;
  },
  [SET_INFORMATION_2_NOTIFICATION](state, payload) {
    state.information2Notification = payload;
  },
  [SET_SELECTED_GROUPS_2_NOTIFICATION](state, payload) {
    state.selectedGroups = payload;
  },
  [SET_INFORMATION_NOTIFICATIONS](state, payload) {
    state.notifications = payload;
  }
};

const getters = {
  [GET_INFORMATIONS]: (state) => state.informations,
  [GET_INFORMATION_2_NOTIFICATION]: (state) => state.information2Notification,
  [GET_SELECTED_GROUPS_2_NOTIFICATION]: (state) => state.selectedGroups,
  [GET_INFORMATION_NOTIFICATIONS]: (state) => state.notifications
};

export default {
  state,
  actions,
  mutations,
  getters
};
