<template>
  <div
    id="app"
    class="flex flex-col h-screen"
    :class="{ 'calendar-body': $route.path.includes('tui-calendar') }"
  >
    <component :is="layout">
      <router-view />
    </component>
    <Toast />

    <LocaleConfirm />
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore, mapGetters } from 'vuex';
import { provideI18n } from '@/plugins/i18nPlugin';
import { updateCookieExpiration } from '@/plugins/utility';
import LocaleConfirm from './components/UI/LocaleConfirm';
import messages from '@/lang';
import {
  GET_LANGUAGE,
  SET_LOGGED_IN,
  GET_LOGGED_IN,
  SET_UI_NOTIFICATION,
  GET_PERMISSIONS
} from './store/types';
import { useRoute, useRouter } from 'vue-router';

const appLayout = 'app-layout';
export default {
  name: 'Home',
  components: {
    LocaleConfirm
  },
  computed: {
    ...mapGetters(['getUINotificationState', 'getUINotificationContent'])
  },

  watch: {
    getUINotificationState: {
      async handler() {
        if (this.getUINotificationState) {
          this.$toast.add({
            severity: this.getUINotificationContent.type,
            summary: this.getUINotificationContent.text,
            life: 3000
          });
          this.$store.commit(SET_UI_NOTIFICATION, {
            showNotification: false,
            notificationType: 'success',
            notificationText: ''
          });
        }
      }
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const lang = computed(() => store.getters[GET_LANGUAGE]);
    const loggedIn = computed(() => store.getters[GET_LOGGED_IN]);

    const layout = computed(() => {
      return route.meta.layout || appLayout;
    });
    // can();
    provideI18n({
      locale: lang.value,
      messages
    });

    //update and check cookie
    window.addEventListener('click', updateCookie, false);
    window.addEventListener('touchStart', updateCookie, false);
    window.addEventListener('keypress', updateCookie, false);

    function updateCookie() {
      updateCookieExpiration();
      store.commit(SET_LOGGED_IN);
    }

    const permissions = computed(() => store.getters[GET_PERMISSIONS]);
    router.beforeEach((to, from, next) => {
      if (to.meta.permissions && to.meta.permissions.length > 0) {
        let isAllowed = permissions.value.some((p) =>
          to.meta.permissions.includes(p)
        );
        if (isAllowed) {
          next();
        }
      }else {
        next();
      }
    });

    return { loggedIn, layout };
  },
  methods: {
    async update() {
      this.prompt = false;
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' });
    }
  },
  data() {
    return {
      prompt: false
    };
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        this.prompt = true;
      });
    }
  }
};
</script>
