import store from '@/store';

export const permissions = {
  computed: {
    permissions() {
      return store.state.auth.permissions;
    }
  },
  methods: {
    can(key) {
      console.log(key)
      if(key == 'none' || key == undefined) return true
      else return this.permissions.includes(key)
    }
  }
}