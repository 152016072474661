export default class AgencyModel {
  constructor(item) {
    if (item) {
      this._id = item._id ? item._id : null;
      this.user_id = item.user_id ? item.user_id : null;
      this.name = item.name ? item.name : null;
      this.category = item.category ? item.category : null;
      this.address = item.address ? item.address : null;
      this.email = item.email ? item.email : null;
      this.phone = item.phone ? item.phone : null;
      this.currency = item.currency ? item.currency : null;
      this.brand_id = item.brand_id ? item.brand_id : [];
      this.tariffs_ids = item.tariffs_ids ? item.tariffs_ids : [];
      this.skills_ids = item.skills_ids ? item.skills_ids : [];
      this.payments_ids = item.payments_ids ? item.payments_ids : [];
      this.pratiques_ids = item.pratiques_ids ? item.pratiques_ids : [];
      this.diplomes_ids = item.diplomes_ids ? item.diplomes_ids : [];
    }
  }

  toJson() {
    return JSON.parse(JSON.stringify(this));
  }
}
