<template>
  <FileUpload
    mode="basic"
    name="demo"
    :accept="`image/*`"
    :maxFileSize="9000000"
    :showUploadButton="false"
    :showCancelButton="false"
    @select="fetchLogo"
    v-if="showUploadBtn"
    :multiple="false"
  >
  </FileUpload>
  <button
    class="clear-upload p-link"
    type="button"
    v-else
    @click="(showUploadBtn = true), $emit('clear')"
  >
    Clear logo
  </button>
</template>

<script>
import { ref } from 'vue';
export default {
  setup(props, { emit }) {
    const showUploadBtn = ref(true);
    function fetchLogo(e) {
      showUploadBtn.value = false;
      emit('uploadLogo', e.files[0]);
    }
    return {
      showUploadBtn,
      fetchLogo
    };
  }
};
</script>