<template>
<div class="p-d-flex p-flex-column">
   <p><section-actions /></p>
</div>
</template>
<script>
import SectionActions from './sections/Sect.vue';
export default {
    components: {
         SectionActions,
  },
    data() {
      return {
        active: 0,
      }
    },
  }
</script>