<template>
  <div class="p-d-flex">
    <div class="p-d-flex p-flex-column p-align-start p-col-3">
      <div class="p-col-12 p-grid">
        <label for="dateformat">{{ i18n.$t('Type Event') }}:</label>
        <Dropdown
          name="events"
          v-model="selectedEvent"
          :options="EVENTS"
          optionLabel="name"
          optionValue="key"
          style="width: 100%"
        />
      </div>
      <div class="p-col-12 p-grid">
        <label for="dateformat">{{ i18n.$t('Categories') }}:</label>
        <MultiSelect
          name="categories"
          v-model="selectedCategories"
          :options="categories"
          optionLabel="name"
          optionValue="id"
          style="width: 100%"
        />
      </div>
    </div>
    <div class="p-col-6 p-d-flex p-align-start">
      <div class="p-col p-grid">
        <label for="dateformat">{{ i18n.$t('Service') }}:</label>
        <MultiSelect
          name="services"
          v-model="selectedService"
          :options="services"
          optionLabel="name"
          optionValue="_id"
          style="width: 100%"
        />
      </div>
      <div class="p-col p-grid p-ml-2">
        <label for="dateformat">{{ i18n.$t('Employee') }}:</label>
        <MultiSelect
          name="employees"
          v-model="selectedEmployee"
          :options="employees"
          optionLabel="first_name"
          optionValue="id"
          style="width: 100%"
        />
      </div>
    </div>
    <div class="p-col-3">
      <div class="p-d-flex p-flex-column p-col-12 p-grid">
        <label for="dateformat">{{i18n.$t('Date')}}:</label>
        <Calendar
          id="dateformat"
          selectionMode="range"
          v-model="date"
          dateFormat="dd MM yy"
        />
      </div>
      <div class="p-d-flex p-justify-end p-col-12 p-grid">
        <button class="p-button" @click="submit">{{i18n.$t('Save')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import useI18n from '@/plugins/useI18n';
import {
  GET_USERDATA,
  GET_SERVICES,
  GET_EMPLOYEES,
  GET_ALL_CATEGORIES,
  GET_SELECTED_CARTEGORY_TREE_VALUE
} from '@/store/types';
import moment from 'moment';

import { EVENTS } from '@/store/constant';

export default {
  setup(props, { emit }) {
    const store = useStore();
    const { i18n } = useI18n();
    const services = computed(() => store.getters[GET_SERVICES]);
    const employees = computed(() => store.getters[GET_EMPLOYEES]);
    const allCategories = computed(() => store.getters[GET_ALL_CATEGORIES]);
    const selectedService = ref([]);
    const selectedEmployee = ref([]);
    const selectedCategories = ref([]);
    const selectedEvent = ref('');
    const categories = ref([]);

    const selectedTreeValue = computed(
      () => store.getters[GET_SELECTED_CARTEGORY_TREE_VALUE]
    );

    let date = ref('');
    let weeklyDates = ref([])

    datesetter()
    function datesetter(){
      for(let i=0; i<7; i++){
        var Ndate = new Date();
        var res = Ndate.setTime(Ndate.getTime() + (i * 24 * 60 * 60 * 1000));
        let Nday = moment(res).format('DD-MMMM-YYYY')
        weeklyDates.value.push(Nday)
      }
      date.value = weeklyDates.value[0] +' - '+ weeklyDates.value[6]
    }

    initData();

    async function initData() {
      getSelectedCategories();
    }

    function getSelectedCategories() {
      const selectedItems = [];

      for (const key in selectedTreeValue.value) {
        selectedItems.push(key);
      }
      if (selectedItems.length > 0) {
        allCategories.value.forEach((el) => {
          if (selectedItems.includes(el.id)) {
            categories.value.push(el);
          }
        });
      }
    }
    function submit() {
      const data = {
        event_type: selectedEvent.value,
        services: selectedService.value,
        categories: selectedCategories.value,
        employees: selectedEmployee.value,
        date_from: moment(date.value[0]).format('DD-MM-YYYY'),
        date_to: moment(date.value[1]).format('DD-MM-YYYY')
      };
      let filterUrl = '';
      for (const key in data) {
        if(data[key].length > 0) {
          console.log('work', Array.isArray(data[key]) && data[key].length > 0)
          let item = `&${key}=${data[key]}`;
          filterUrl += item;
        }
      }
      emit('filter', filterUrl);
    }

    return {
      services,
      employees,
      // events,
      categories,
      selectedCategories,
      selectedEvent,
      selectedService,
      selectedEmployee,
      date,
      i18n,
      EVENTS,
      submit,
    };
  }
};
</script>