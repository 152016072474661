import axios from '../plugins/axios';

import {
  FETCH_CONTACTS,
  SET_CONTACTS,
  GET_CONTACTS,
  SET_SELECTED_CONTACTS,
  GET_SELECTED_CONTACTS
} from './types';

const state = {
  contacts: [],
  selectedContacts: []
};

const actions = {
  async [FETCH_CONTACTS](context, owner_id) {
    const res = await axios.get(`contact?owner_id=${owner_id}`);
    context.commit(SET_CONTACTS, res.data.data.items);
  }
};

const mutations = {
  [SET_CONTACTS](state, payload) {
    state.contacts = payload;
  },
  [SET_SELECTED_CONTACTS](state, payload) {
    state.selectedContacts = payload;
  }
};

const getters = {
  [GET_CONTACTS]: (state) => state.contacts,
  [GET_SELECTED_CONTACTS]: (state) => state.selectedContacts
};

export default {
  state,
  actions,
  mutations,
  getters
};
