import axios from '../plugins/axios';

import { FETCH_QRCODES, SET_QRCODES, GET_QRCODES } from './types';

const state = {
  qrcodes: []
};

const actions = {
  async [FETCH_QRCODES](context, owner_id) {
    const res = await axios.get(`qrcode?owner_id=${owner_id}`);
    context.commit(SET_QRCODES, res.data.data.items);
  }
};

const mutations = {
  [SET_QRCODES](state, payload) {
    state.qrcodes = payload;
  }
};

const getters = {
  [GET_QRCODES]: (state) => state.qrcodes
};

export default {
  state,
  actions,
  mutations,
  getters
};
