import moment from 'moment';
import { COOKIE_EXPIRATION_TIME } from '../store/constant';

const tempDate = '2020-01-01 ';

export function durationMinutes(st, et) {
  const startTime = moment(tempDate + st);
  const endTime = moment(tempDate + et);
  const duration = moment.duration(endTime.diff(startTime));
  return duration.asMinutes();
}

export function addTime(tm, delta) {
  const date = moment.utc(tempDate + tm);
  return date.add(delta, 'minute').format('HH:mm');
}
export function subtractTime(tm, delta) {
  const date = moment.utc(tempDate + tm);
  return date.subtract(delta, 'minute').format('HH:mm');
}

export function addMinutesToDate(startDate, delta) {
  const date = moment.utc(startDate);
  return date.add(delta, 'minute').format('YYYY-MM-DD HH:mm');
}

export function updateCookieExpiration() {
  const loggedIn = localStorage.getItem('loggedIn');
  if (loggedIn) {
    const expiration = parseInt(localStorage.getItem('expiration'));
    if (expiration > new Date().getTime()) {
      localStorage.setItem(
        'expiration',
        new Date().getTime() + COOKIE_EXPIRATION_TIME * 1000
      );
    } else {
      localStorage.removeItem('loggedIn');
      localStorage.removeItem('expiration');
      localStorage.removeItem('userData');
    }
  }
}

export function formatDate(date) {
  return moment.utc(date).format('YYYY-MM-DD HH:mm');
}

export function formatSimpleDate(date) {
  return moment.utc(date).format('DD/MM/YYYY');
}

export function loadLogo(logo) {
  if(logo !== `${process.env.VUE_APP_HOST_MANAGER}${process.env.VUE_APP_IMAGE_PATH}`) {
    return logo
  } else return null
}