
import {
  GET_USERDATA,
  SET_USERDATA,
  CLEAR_USERDATA
} from './types';

const state = {
  address: "",
  currency_id: "",
  email: "",
  first_name: "",
  id: "",
  language_id: "",
  last_name: "",
  login: true,
  organisation_id: "",
  organisation_name: "",
  phone: null,
  photo: "",
  role: "",
  prefix: 33,
  status: 0,
  token: "",
  username: "",
  photoUrl: "",
};

const actions = {
};

const mutations = {
  [SET_USERDATA](state, payload) {
    for (let key in payload) {
      state[key] = payload[key];
    }
    localStorage.setItem('lang', JSON.stringify(payload.language_id))
  },
  [CLEAR_USERDATA](state) {
    for (let key in state) {
      state[key] = '';
    }
    state.prefix = 33
    state.status = 0
  }
};

const getters = {
  [GET_USERDATA]: (state) => state,
};

export default {
  state,
  actions,
  mutations,
  getters
};
