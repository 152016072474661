<template>
  <div class="chart-container p-mt-2 p-d-flex p-flex-column p-align-center">
    <div class="chart-layout p-mb-5" v-if="chartDataInformation">
      <h3>Information:</h3>
      <Chart type="doughnut" :data="chartDataInformation" />
    </div>
    <div class="chart-layout p-mb-5" v-if="chartDataInteraction">
      <h3>Interaction:</h3>
      <Chart type="doughnut" :data="chartDataInteraction" />
    </div>
    <div class="chart-layout p-mb-5" v-if="chartDataRDv">
      <h3>RDv:</h3>
      <Chart type="doughnut" :data="chartDataRDv" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { GET_REPORTING, FETCH_AGENCY } from '@/store/types';
export default {
  setup() {
    const store = useStore();
    const reporting = computed(() => store.getters[GET_REPORTING]);
    const data = reporting.value != null ? reporting.value[0] : null;

    console.log('sssssssss', data);

    const chartDataInformation = computed(() => {
      if (data == null) return false;
      return {
        labels: [
          `Read: ${data.information.read}`,
          `Sent: ${data.information.sent}`,
          `Total: ${data.information.total}`
        ],
        datasets: [
          {
            data: [
              data.information.read,
              data.information.sent,
              data.information.total
            ],
            backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
            hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D']
          }
        ]
      };
    });
    const chartDataInteraction = computed(() => {
      if (data == null) return false;
      return {
        labels: [
          `Event: ${data.information.interaction.event}`,
          `RDV: ${data.information.interaction.rdv}`,
          `Task: ${data.information.interaction.task}`,
          `Total: ${data.information.interaction.total}`
        ],
        datasets: [
          {
            data: [
              data.information.interaction.event,
              data.information.interaction.rdv,
              data.information.interaction.task,
              data.information.interaction.total
            ],
            backgroundColor: ['#42A5F5', '#66BB6A', '#f00', '#FFA524'],
            hoverBackgroundColor: ['#64B5F6', '#81C784', '#f00', '#FFA524']
          }
        ]
      };
    });

    store.dispatch(FETCH_AGENCY);

    const chartDataRDv = computed(() => {
      if (data == null) return false;
      return {
        labels: [
          `Percentage: ${data.rdv.percentage}`,
          `Total ${data.rdv.total}`,
          `Total_Bookable: ${data.rdv.total_bookable}`
        ],
        datasets: [
          {
            data: [
              data.rdv.percentage,
              data.rdv.total,
              data.rdv.total_bookable
            ],
            backgroundColor: ['#42A5F5', '#66BB6A', '#FFA524'],
            hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFA524']
          }
        ]
      };
    });
    return {
      chartDataInformation,
      chartDataInteraction,
      chartDataRDv
    };
  }
};
</script>

<style scoped>
.chart-layout {
  width: 400px;
}
</style>