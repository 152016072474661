<template>
  <div class="p-d-flex p-flex-column">
        <div class="p-grid">
        
        <div class="p-col">
            <div class="card p-mb-2">
                <TabView class="tabview-custom" ref="tabview2" v-model:activeIndex="active">
                    <TabPanel>
                        <template #header>
                            <span>{{ i18n.$t("Header") }} I</span>
                            <br>
                            <i class="pi pi-calendar"></i>            
                        </template>
                        <div class="p-d-flex">
                            <div class="p-col-fixed prev-icon" style="width:60px">                          
                            </div>
                            <div class="p-col"><p><section1-actions /></p></div>
                            <div class="p-col-fixed next-icon" style="width:60px">
                                <Button @click="active = 1" class="p-button-text" label=" >> " />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <span>{{ i18n.$t("Header") }} II</span>
                            <i class="pi pi-user"></i>
                        </template>
                        <div class="p-d-flex">
                            <div class="p-col-fixed prev-icon" style="width:60px">
                                <Button @click="active = 0" class="p-button-text" label=" << " />
                            </div>
                            <div class="p-col"><p><section2-actions /></p></div>
                            <div class="p-col-fixed next-icon" style="width:60px">
                                <Button @click="active = 2" class="p-button-text" label=" >> " />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-search"></i>
                            <span>{{ i18n.$t("Header") }} III</span>
                        </template>
                       <div class="p-d-flex">
                            <div class="p-col-fixed prev-icon" style="width:60px">
                                <Button @click="active = 1" class="p-button-text" label=" << " />
                            </div>
                            <div class="p-col"><p><section3-actions /></p></div>
                            <div class="p-col-fixed next-icon" style="width:60px">
                                <Button @click="active = 3" class="p-button-text" label=" >> " />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <span>{{ i18n.$t("Header") }} 4</span>
                            <br>
                            <i class="pi pi-calendar"></i>           
                        </template>
                        <div class="p-d-flex">
                            <div class="p-col-fixed prev-icon" style="width:60px">
                                <Button @click="active = 2" class="p-button-text" label=" << " />
                            </div>
                            <div class="p-col"><p><section4-actions /></p></div>
                            <div class="p-col-fixed next-icon" style="width:60px">
                                <Button @click="active = 4" class="p-button-text" label=" >> " />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <span>{{ i18n.$t("Header") }} 5</span>
                            <i class="pi pi-user"></i>
                        </template>
                        <div class="p-d-flex">
                            <div class="p-col-fixed prev-icon" style="width:60px">
                                <Button @click="active = 3" class="p-button-text" label=" << " />
                            </div>
                            <div class="p-col"><p><section5-actions /></p></div>
                            <div class="p-col-fixed next-icon" style="width:60px">
                                <Button @click="active = 5" class="p-button-text" label=" >> " />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-search"></i>
                            <span>{{ i18n.$t("Header") }} 6</span>
                        </template>
                        <div class="p-d-flex">
                            <div class="p-col-fixed prev-icon" style="width:60px">
                                <Button @click="active = 4" class="p-button-text" label=" << " />
                            </div>
                            <div class="p-col"><p><section6-actions /></p></div>
                            <div class="p-col-fixed next-icon" style="width:60px">
                                <Button @click="active = 6" class="p-button-text" label=" >> " />
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel>
                        <template #header>
                            <span>{{ i18n.$t("Header") }} 7</span>
                            <i class="pi pi-cog"></i>
                        </template>
                        <div class="p-d-flex">
                            <div class="p-col-fixed prev-icon" style="width:60px">
                                <Button @click="active = 5" class="p-button-text" label=" << " />
                            </div>
                            <div class="p-col"><p><section7-actions /></p></div>
                            <div class="p-col-fixed next-icon" style="width:60px">
                                <Button @click="active = 0" class="p-button-text" label=" >> " />
                            </div>
                        </div>             
                    </TabPanel>           
                </TabView>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Section1Actions from './Sect1.vue';
import Section2Actions from './Sect2.vue';
import Section3Actions from './Sect3.vue';
import Section4Actions from './Sect4.vue';
import Section5Actions from './Sect5.vue';
import Section6Actions from './Sect6.vue';
import useI18n from '@/plugins/useI18n';
import Section7Actions from './Sect7.vue';
export default {
components: {
    Section1Actions,
    Section2Actions,
    Section3Actions,
    Section4Actions,
    Section5Actions,
    Section6Actions,
    Section7Actions
  },
  data() {
      return {
        active: 0,
      }
    },
    setup() {
    const { i18n } = useI18n();
    return {
      i18n
    }
  }
};
</script>
<style lang="scss" scoped>
.tabview-custom {
	i, span {
		vertical-align: middle;
	}

	span {
		margin: 0 .5rem;
	}
}
.prev-icon {
    position: absolute;
    left: 0;
}
.next-icon {
    position: absolute;
    right: 0;
}
</style>