import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import ui from './ui';
import auth from './auth';
import lang from './lang';
import agency from './agency';
import rdv from './rdv';
import documents from './documents';
import subscriptions from './subscription';
import contacts from './contacts';
import groups from './groups';
import events from './events';
import notes from './notes';
import agenda from './agenda';
import category from './category';
import employee from './employee';
import information from './information';
import qrcode from './qrcode';
import userData from './userData';

const vuexLocal = new VuexPersistence({
  key: 'vuex',
  storage: window.localStorage,
  // reducer: state => {
  //   return {};
  // }
});

export default createStore({
  modules: {
    userData,
    ui,
    auth,
    lang,
    agency,
    rdv,
    documents,
    subscriptions,
    contacts,
    groups,
    events,
    notes,
    employee,
    information,
    qrcode,
    agenda,
    category
  },
  plugins: [vuexLocal.plugin]
});
