<template>
  <Tree
    :value="treeValue"
    v-model:selectionKeys="selectedTreeValue"
    selectionMode="checkbox"
    @node-select="addSelect"
    @node-unselect="removeSelect"
    v-if="selectedTreeValue"
  >
    <template #default="slotProps">
      {{ slotProps.node.label }}
      <div v-if="slotProps.node.description != ''">
        <br />
        <small>{{ slotProps.node.description }}</small>
      </div>
    </template>
  </Tree>
</template>

<script>
import { ref } from 'vue';
export default {
  props: {
    treeValue: {
      type: Object,
      default: () => {}
    },
    checked: {
      type: Object
    }
  },
  setup(props, { emit }) {
    const selectedTreeValue = ref([]);
    const selectedItems = ref([]);
    if (props.checked) {
      selectedItems.value = props.checked;
      props.checked.forEach((el) => {
        selectedTreeValue.value[el] = {
          checked: true
        };
      });
    }
    function addSelect(item) {
      if (item.childs.length == 0) {
        selectedItems.value.push(item.id);
        emit('selectedItems', selectedItems.value);
      }
      const selected =
        selectedItems.value.length > 0 ? selectedItems.value : [];
      for (const key in selectedTreeValue.value) {
        if (!selected.includes(key)) {
          selectedTreeValue.value[key].checked = false;
        }
      }
    }

    function removeSelect(item) {
      const index = selectedItems.value.indexOf(item);
      selectedItems.value.splice(index, 1);
      emit('selectedItems', selectedItems.value);
    }
    return {
      selectedTreeValue,
      selectedItems,
      addSelect,
      removeSelect
    };
  }
};
</script>