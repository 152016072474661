<template>
  <Suspense>
    <template #default>
      <img
        :src="``"
        class="logo-image"
        alt="Logo"
      />
    </template>
    <template #fallback>
      <div class="lazy-image"></div>
    </template>
  </Suspense>
</template>

<style lang="scss" scoped>
.logo-image {
  width: 280px;
}

@media (max-width: 1023px) {
  .logo-image {
    width: 150px;
    /* height: 40px; */
  }
}
@media (max-width: 767px) {
  .logo-image {
    width: 50px;
    height: 50px;
  }
}
</style>
