<template>
  <footer class="public-footer">
    <div class="footer-container">
      <div class="heading">
        <h4>{{ i18n.$t("Apin") }}</h4>
        <div class="footer-links">
          <ul>
            <li><a href="#">{{ i18n.$t("Features") }}</a></li>
            <li><a href="#">{{ i18n.$t("Security") }}</a></li>
            <li><a href="#">{{ i18n.$t("Download") }}</a></li>
            <li><a href="#">{{ i18n.$t("Business") }}</a></li>
            <li><a href="#">{{ i18n.$t("Privacy") }}</a></li>
            <li><a href="#">{{ i18n.$t("Payment") }}</a></li>
          </ul>
        </div>
      </div>
      <div class="heading">
        <h4>{{ i18n.$t("Company") }}</h4>
        <div class="footer-links">
          <ul>
            <li><a href="#">{{ i18n.$t("About") }}</a></li>
            <li><a href="#">{{ i18n.$t("Careers") }}</a></li>
          </ul>
        </div>
      </div>
      <div class="heading">
        <h4>{{ i18n.$t("Download") }}</h4>
        <div class="footer-links">
          <ul>
            <li><a href="#">{{ i18n.$t("Mac/PC") }}</a></li>
            <li><a href="#">{{ i18n.$t("Android") }}</a></li>
            <li><a href="#">{{ i18n.$t("Iphone") }}</a></li>
          </ul>
        </div>
      </div>
      <div class="heading">
        <h4>{{ i18n.$t("Help") }}</h4>
        <div class="footer-links">
          <ul>
            <li><a href="#">{{ i18n.$t("FAQ") }}</a></li>
            <li><a href="#">{{ i18n.$t("Twitter") }}</a></li>
            <li><a href="#">{{ i18n.$t("Facebook") }}</a></li>
            <li><a href="#">{{ i18n.$t("Coronavirus") }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  <div class="copyright-content">
    <div class="copyright-text">
      <h5>2021 &copy;</h5>
    </div>
  </div>
</template>

<script>
import useI18n from '@/plugins/useI18n';
export default {
  setup() {
    const { i18n } = useI18n();
    return {
      i18n
    }
  }
};
</script>

<style lang="scss" scoped>
footer.public-footer {
  background-color: #273443;
  padding-bottom: 40px;
  padding-top: 53px;
  width: 100%;
}
.footer-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
footer .heading {
  width: 25%;
  float: left;
}
footer .heading h4 {
  color: rgba(255, 255, 255, .6);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .08em;
  line-height: 14px;
  margin-bottom: 22px;
  text-transform: uppercase;
}
footer .footer-links ul {
  line-height: normal;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
footer .footer-links ul li {
  color: rgba(255, 255, 255, .6);
  font-size: 14px;
  margin-bottom: 6px;
}
footer .footer-links ul li a {
  color: #fff;
}
.copyright-content {
  background-color: #232f3c;
  padding: 23px 0;
}
.copyright-content .copyright-text {
  max-width: 1000px;
  margin: auto;
  text-align: center;
  width: 90%;
}
.copyright-content .copyright-text h5 {
  margin: 0;
  color: #fff;
}
@media (max-width: 767px) {
  footer .heading {
    width: 48%;
    float: left;
    height: 240px;
    margin-bottom: 15px;
  }
  footer.public-footer {
    padding-bottom: 0;
  }
  .footer-container {
    padding: 0 30px;
    display: inline-block;
  }
  footer .footer-links ul li {
    padding: 5px 0;
  }
}
</style>