<template>
  <TabView>
    <TabPanel header="Reporting">
      <Filter @filter="onSubmit" />
      <Reporting v-model="reporting" />
    </TabPanel>
    <TabPanel header="Analistic">
      <Filter />
      <Analytics />
    </TabPanel>
  </TabView>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { SET_REPORTING } from '@/store/types';
import {
  FETCH_ALL_SERVICES,
  FETCH_EMPLOYEES,
  FETCH_ALL_CATEGORIES,
  GET_USERDATA
} from '@/store/types';
import axios from '@/plugins/axios';
import moment from 'moment';
import Reporting from '@/components/Dashboard/Reporting.vue';
import Analytics from '@/components/Dashboard/Analytics.vue';
import Filter from '@/components/Dashboard/Filter.vue';

export default {
  components: { Reporting, Filter, Analytics },
  setup() {
    const store = useStore();

    const userData = computed(() => store.getters[GET_USERDATA]);

    const router = useRouter();

    initData();
    onSubmit();
    
    async function initData() {
      await store.dispatch(FETCH_ALL_SERVICES);
      await store.dispatch(FETCH_ALL_CATEGORIES);
      await store.dispatch(FETCH_EMPLOYEES, userData.value.organisation_id);
    }

    async function onSubmit(filterUrl) {
      if (!filterUrl) {
        const today = moment().format('DD-MM-YYYY');
        filterUrl = `&date_from=${today}&date_to=${today}`;
      }
      try {
        const res = await axios.get(
          `/tools/stats?organisation_id=${userData.value.organisation_id}${filterUrl}`
        );
        store.commit(SET_REPORTING, res.data.data);
        if (res.status == 200) {
          router.push({ path: '/' });
        }
      } catch(err) {
        console.log(err)
      }
    }

    return {
      onSubmit
    };
  }
};
</script>
<style>
.p-tabview .p-tabview-panels {
  padding: 1rem 0 !important;
}
</style>
<style lang="scss" scoped></style>
