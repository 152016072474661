<template>
  <div class="terms-conditions">
    <p class="text-left">SECTION5 VUE
    </p>
  </div>
</template>
<script>
import useI18n from '@/plugins/useI18n';
export default {
  setup() {
    const { i18n } = useI18n();
    return {
      i18n
    }
  }
};
</script>