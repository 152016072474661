import axios from '../plugins/axios';
import _ from 'lodash';

import {
  FETCH_NOTES,
  SET_NOTES,
  GET_NOTES,
  FETCH_NOTE_BY_ID,
  SET_ADD_NOTE
} from './types';

const state = {
  notes: []
};

const actions = {
  async [FETCH_NOTES](context, owner_id) {
    const res = await axios.get(`note?owner_id=${owner_id}`);
    context.commit(SET_NOTES, res.data.data.items);
  },

  async [FETCH_NOTE_BY_ID](context, { noteId }) {
    const note = _.find(context.state.notes, function (o) {
      return o._id === noteId;
    });
    return note ? note : {};
  }
};

const mutations = {
  [SET_NOTES](state, payload) {
    state.notes = payload;
  },
  [SET_ADD_NOTE](state, payload) {
    state.notes.push(payload);
  }
};

const getters = {
  [GET_NOTES]: (state) => state.notes
};

export default {
  state,
  actions,
  mutations,
  getters
};
