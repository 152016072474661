<template>
  <div
    class="p-inputgroup"
    :class="[{ 'start-valid': validation }, { invalid: invalid && validation }]"
  >
    <slot></slot>
    <i class="pi pi-times" v-if="invalid && validation"></i>
    <i class="pi pi-check" v-else-if="validation"></i>
    <span class="error-message" v-if="invalid && validation && text">{{
      i18n.$t('Field required')
    }}</span>
    <span class="success-message" v-if="!invalid && validation && text">{{
      i18n.$t('the data is correct')
    }}</span>
  </div>
</template>

<script>
import useI18n from '@/plugins/useI18n';

export default {
  props: {
    invalid: {
      type: Boolean
    },
    validation: {
      type: Boolean
    },
    text: {
      type: Boolean
    }
  },
  setup() {
    const { i18n } = useI18n();

    return {
      i18n
    };
  }
};
</script>

<style lang="scss">
.p-inputgroup {
  position: relative;
  &.start-valid {
    .p-dropdown .p-dropdown-trigger,
    .p-multiselect .p-multiselect-trigger {
      display: none;
    }
  }
  &.invalid {
    .p-inputwrapper,
    .p-inputtext {
      border: 1px solid red;
      .p-inputtext {
        border: none;
      }
    }
  }
  .pi {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    &.pi-times {
      color: red;
    }
    &.pi-times.p-multiselect-close-icon {
      color: inherit;
    }
    &.pi-check {
      color: green;
    }
  }
  .error-message {
    color: red;
  }
}
.success-message,
.error-message {
  font-size: 10px;
  position: absolute;
  bottom: -15px;
  right: 0;
}
.success-message {
  color: green;
}
.phone-select.p-inputgroup {
  position: relative;
  display: block;
  input {
    padding-left: 170px;
  }
  &.invalid {
    .phone-input {
      .p-dropdown {
        border: none;
      }
    }
  }
  .p-inputtext,
  .p-inputwrapper {
    width: auto !important;
    flex: none !important;
  }
  .p-inputtext {
    width: 100% !important;
    &.p-dropdown-label {
      width: 120px !important;
    }
  }
  .p-dropdown .p-dropdown-trigger {
    position: relative;
  }
}
.p-inputgroup .overlay {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
