import {
  createRouter,
  createWebHashHistory
} from 'vue-router';
import {
  updateCookieExpiration
} from '../plugins/utility';

import Home from '../views/Public/Home.vue';
import Prices from '../views/Public/Prices.vue';
import Register from '../views/Public/Register.vue';
import Dashboard from '../views/Dashboard/Index.vue';

const routes = [{
    path: '/',
    name: 'app',
    component: Dashboard
  },
  {
    path: '/public/home',
    name: 'public-home',
    meta: {
      layout: 'public-layout'
    },
    component: Home
  },
  {
    path: '/public/register',
    name: 'public-register',
    meta: {
      layout: 'public-layout'
    },
    component: Register
  },
  {
    path: '/public/prices',
    name: 'public-prices',
    meta: {
      layout: 'public-layout'
    },
    component: Prices
  },
  {
    path: '/reporting',
    name: 'reporting',
    component: () =>
      import( /* webpackChunkName: "connect" */ '../views/Dashboard/Index.vue')
  },
  {
    path: '/public/terms',
    name: 'public-terms',
    meta: {
      layout: 'public-layout'
    },
    component: () =>
      import( /* webpackChunkName: "connect" */ '../views/Public/Terms.vue')
  },
  {
    path: '/public/changepassword',
    name: 'public-changepassword',
    meta: {
      layout: 'public-layout'
    },
    component: () =>
      import(
        /* webpackChunkName: "changepassword" */
        '../views/Public/ChangePassword.vue'
      )
  },
  {
    path: '/public/resetpassword/:id',
    name: 'public-resetpassword',
    meta: {
      layout: 'public-layout'
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "resetpassword" */
        '../views/Public/ResetPassword.vue'
      )
  },
  {
    path: '/profile/profile',
    meta: {
      permissions: ['v1.user.update']
    },
    name: 'profile-profile',
    component: () =>
      import(
        /* webpackChunkName: "multi-assign-group" */
        '../views/Profile/Profile.vue'
      )
  },
  {
    path: '/profile/myprofile',
    name: 'profile-myprofile',
    meta: {
      permissions: ['v1.user.index']
    },
    component: () =>
      import(
        /* webpackChunkName: "multi-assign-group" */
        '../views/Profile/MyProfile.vue'
      )
  },
  {
    path: '/profile/settings',
    name: 'settings',
    meta: {
      permissions: ['v1.setting.index']
    },
    component: () => import('../views/Settings/directory/Settings')
  },



  {
    path: '/get-started',
    name: 'get-started',
    component: () =>
      import(
        /* webpackChunkName: "changepassword" */
        '../views/Getstarted/GetStarted.vue'
      )
  },
  {
    path: '/employee/list',
    name: 'employee-list',
    component: () =>
      import(
        /* webpackChunkName: "multi-assign-group" */
        '../views/Employee/List.vue'
      )
  },
  {
    path: '/employee/invit',
    name: 'employee-invit',
    component: () => import('../views/Employee/Invit.vue')
  },
  {
    path: '/employee/add',
    name: 'employee-add',
    component: () =>
      import(
        /* webpackChunkName: "multi-assign-group" */
        '../views/Employee/Add.vue'
      )
  },
  {
    path: '/employee/edit',
    name: 'employee-edit',
    component: () =>
      import(
        /* webpackChunkName: "multi-assign-group" */
        '../views/Employee/Edit.vue'
      )
  },
  {
    path: '/employee/schedule',
    name: 'employee-schedule',
    component: () =>
      import(
        /* webpackChunkName: "multi-assign-group" */
        '../views/Employee/Schedule.vue'
      )
  },

  {
    path: '/agency',
    name: 'agency',
    meta: {
      permissions: ['v1.organisation.view']
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-form" */
        '../views/Agency/Agency.vue'
      )
  },
  {
    path: '/agency/edit',
    name: 'agency-edit',
    meta: {
      permissions: ['v1.organisation.update']
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-form" */
        '../views/Agency/AgencyEdit.vue'
      )
  },
  {
    path: '/service/list',
    name: 'service-list',
    meta: {
      permissions: ['v1.service.index']
    },
    component: () => import('../views/Service/List.vue'),
  },
  {
    path: '/service/view',
    name: 'service-view',
    meta: {
      permissions: ['v1.service.view']
    },
    component: () => import('../views/Service/View.vue'),
    children: [{
        path: '/service/view/step1',
        name: 'service-view-step1',
        component: () => import('../components/Service/Step1'),
      },
      {
        path: '/service/view/step2',
        name: 'service-view-step2',
        component: () => import('../components/Service/Step2.vue'),
      },
      {
        path: '/service/view/step3',
        name: 'service-view-step3',
        component: () => import('../components/Service/Step3.vue'),
      },
    ]
  },
  {
    path: '/service/add',
    name: 'service-add',
    meta: {
      permissions: ['v1.service.create']
    },
    component: () => import('../views/Service/add'),
    children: [{
        path: '/service/add/step1',
        name: 'ServiceAddStep1',
        component: () => import('../views/Service/add/Step1.vue'),
      },
      {
        path: '/service/add/step2',
        name: 'ServiceAddStep2',
        component: () => import('../views/Service/add/Step2.vue'),
      },
      {
        path: '/service/add/step3',
        name: 'ServiceAddStep3',
        component: () => import('../views/Service/add/Step3.vue'),
      },
    ]
  },
  {
    path: '/information/send-notification',
    name: 'information-send-notification',
    component: () =>
      import(
        /* webpackChunkName: "information-edit" */
        '../views/Information/SendNotification.vue'
      )
  },
  {
    path: '/information/notification',
    name: 'information-notification',
    component: () =>
      import(
        /* webpackChunkName: "information-edit" */
        '../views/Information/InformationNotification.vue'
      )

  },
  {
    path: '/service/edit',
    name: 'service-edit',
    meta: {
      permissions: ['v1.service.update']
    },
    component: () => import('../views/Service/edit'),
    children: [{
        path: '/service/edit/step1',
        name: 'ServiceEditStep1',
        component: () => import('../views/Service/edit/Step1.vue'),
      },
      {
        path: '/service/edit/step2',
        name: 'ServiceEditStep2',
        component: () => import('../views/Service/edit/Step2.vue'),
      },
      {
        path: '/service/edit/step3',
        name: 'ServiceEditStep3',
        component: () => import('../views/Service/edit/Step3.vue'),
      },
    ]
  },
  {
    path: '/document/list',
    name: 'document-list',
    component: () =>
      import(
        /* webpackChunkName: "agency-form" */
        '../views/Document/Document.vue'
      )
  },
  {
    path: '/document/add',
    name: 'document-add',
    component: () =>
      import(
        /* webpackChunkName: "agency-form" */
        '../views/Document/DocumentAdd.vue'
      )
  },
  {
    path: '/document/edit',
    name: 'document-edit',
    component: () =>
      import(
        /* webpackChunkName: "agency-form" */
        '../views/Document/DocumentEdit.vue'
      )
  },
  {
    path: '/document/view',
    name: 'document-view',
    component: () =>
      import(
        /* webpackChunkName: "agency-form" */
        '../views/Document/DocumentView.vue'
      )
  },
  {
    path: '/contacts',
    name: 'contacts',
    meta: {
      permissions: ['v1.contact.index']
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-form" */
        '../views/Contact/Contact.vue'
      )
  },
  {
    path: '/contacts/list',
    name: 'contacts-list',
    component: () =>
      import(
        /* webpackChunkName: "agency-form" */
        '../views/Contact/Contact.vue'
      )
  },
  {
    path: '/contacts/add',
    name: 'contacts-add',
    meta: {
      permissions: ['v1.contact.create']
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-form" */
        '../views/Contact/ContactAdd.vue'
      )
  },
  {
    path: '/contacts/edit',
    name: 'contacts-edit',
    meta: {
      permissions: ['v1.contact.update']
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-form" */
        '../views/Contact/ContactEdit.vue'
      )
  },
  {
    path: '/contacts/assign-group',
    name: 'contacts-assign-group',
    meta: {
      permissions: ['v1.contact.invit']
    },
    component: () =>
      import(
        /* webpackChunkName: "assign-group" */
        '../views/Contact/AssignGroup.vue'
      )
  },
  {
    path: '/contacts/multi-assign-group',
    meta: {
      permissions: ['v1.contact.invit']
    },
    name: 'contacts-multi-assign-group',
    component: () =>
      import(
        /* webpackChunkName: "multi-assign-group" */
        '../views/Contact/MultiAssignGroup.vue'
      )
  },

  {
    path: '/groups/list',
    name: 'groups-list',
    meta: {
      permissions: ['v1.group.index']
    },
    component: () =>
      import(
        /* webpackChunkName: "multi-assign-group" */
        '../views/Group/Group.vue'
      )
  },
  {
    path: '/groups/add',
    name: 'groups-add',
    meta: {
      permissions: ['v1.group.create']
    },
    component: () =>
      import(
        /* webpackChunkName: "multi-assign-group" */
        '../views/Group/GroupAdd.vue'
      )
  },
  {
    path: '/groups/edit',
    name: 'groups-edit',
    meta: {
      permissions: ['v1.group.update']
    },
    component: () =>
      import(
        /* webpackChunkName: "multi-assign-group" */
        '../views/Group/GroupEdit.vue'
      )
  },
  {
    path: '/about',
    name: 'about',
    component: () =>
      import( /* webpackChunkName: "multi-assign-group" */ '../views/About.vue')
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () =>
      import(
        /* webpackChunkName: "multi-assign-group" */
        '../views/Configuration.vue'
      )
  },
  {
    path: '/information/list',
    name: 'information-list',
    component: () =>
      import(
        /* webpackChunkName: "information-list" */
        '../views/Information/Informations.vue'
      ),
    meta: {
      permissions: ['v1.information.index']
    },
  },
  {
    path: '/information/add',
    name: 'information-add',
    meta: {
      permissions: ['v1.information.create']
    },
    component: () =>
      import(
        /* webpackChunkName: "information-add" */
        '../views/Information/add'
      ),
    children: [{
        path: '/information/add/step1',
        name: 'InformationAddStep1',
        meta: {
          permissions: ['v1.information.create']
        },
        component: () => import(
          /* webpackChunkName: "information-add" */
          '../views/Information/steps/Step1.vue'
        ),
      },
      {
        path: '/information/add/step2',
        name: 'InformationAddStep2',
        meta: {
          permissions: ['v1.information.create']
        },
        component: () => import(
          /* webpackChunkName: "information-add" */
          '../views/Information/steps/Step2.vue'
        ),
      },
      {
        path: '/information/add/step3',
        name: 'InformationAddStep3',
        meta: {
          permissions: ['v1.information.create']
        },
        component: () => import(
          /* webpackChunkName: "information-add" */
          '../views/Information/steps/Step3.vue'
        ),
      },
      {
        path: '/information/add/step4',
        name: 'InformationAddStep4',
        meta: {
          permissions: ['v1.information.create']
        },
        component: () => import(
          /* webpackChunkName: "information-add" */
          '../views/Information/steps/Step4.vue'
        ),
      },
    ]
  },
  {
    path: '/information/view',
    name: 'information-view',
    meth: {
      permissions: ['v1.information.view']
    },
    component: () =>
      import(
        /* webpackChunkName: "information-add" */
        '../views/Information/InformationView.vue'
      )
  },
  {
    path: '/information/edit',
    name: 'information-edit',
    meth: {
      permissions: ['v1.information.update']
    },
    component: () =>
      import(
        /* webpackChunkName: "information-edit" */
        '../views/Information/edit/index.vue'
      ),
    children: [{
        path: '/information/edit/step1',
        name: 'InformationEditStep1',
        meta: {
          permissions: ['v1.information.update']
        },
        component: () => import(
          /* webpackChunkName: "information-add" */
          '../views/Information/steps/Step1.vue'
        ),
      },
      {
        path: '/information/edit/step2',
        name: 'InformationEditStep2',
        meta: {
          permissions: ['v1.information.update']
        },
        component: () => import(
          /* webpackChunkName: "information-add" */
          '../views/Information/steps/Step2.vue'
        ),
      },
      {
        path: '/information/edit/step3',
        name: 'InformationEditStep3',
        meta: {
          permissions: ['v1.information.update']
        },
        component: () => import(
          /* webpackChunkName: "information-add" */
          '../views/Information/steps/Step3.vue'
        ),
      },
      {
        path: '/information/edit/step4',
        name: 'InformationEditStep4',
        meta: {
          permissions: ['v1.information.update']
        },
        component: () => import(
          /* webpackChunkName: "information-add" */
          '../views/Information/steps/Step4.vue'
        ),
      },
    ]
  },
  {
    path: '/information/send-notification',
    name: 'information-send-notification',
    // meth: {
    //   permissions: ['v1.information.update']
    // },
    component: () =>
      import(
        /* webpackChunkName: "information-edit" */
        '../views/Information/SendNotification.vue'
      )
  },
  {
    path: '/information/notification',
    name: 'information-notification',
    component: () =>
      import(
        /* webpackChunkName: "information-edit" */
        '../views/Information/InformationNotification.vue'
      )
  },

  {
    path: '/note',
    name: 'note',
    meta: {
      permissions: ['v1.note.index']
    },
    component: () =>
      import( /* webpackChunkName: "note" */ '../views/Note/Note.vue')
  },
  {
    path: '/note/add',
    name: 'note-add',
    meta: {
      permissions: ['v1.note.create']
    },
    component: () =>
      import( /* webpackChunkName: "note" */ '../views/Note/NoteAdd.vue')
  },
  {
    path: '/note/view',
    name: 'note-view',
    meta: {
      permissions: ['v1.note.view']
    },
    component: () =>
      import( /* webpackChunkName: "note" */ '../views/Note/NoteView.vue')
  },
  {
    path: '/note/edit',
    name: 'note-edit',
    meta: {
      permissions: ['v1.note.update']
    },
    component: () =>
      import( /* webpackChunkName: "note" */ '../views/Note/NoteEdit.vue')
  },


  {
    path: '/events/rdvlist',
    name: 'events-rdv-list',
    component: () =>
      import(
        /* webpackChunkName: "events-rdv-list" */
        '../views/Event/Events.vue'
      )
  },
  {
    path: '/events/eventlist',
    name: 'events-event-list',
    component: () =>
      import(
        /* webpackChunkName: "events-event-list" */
        '../views/Event/Events.vue'
      )
  },
  {
    path: '/events/tasklist',
    name: 'events-task-list',
    component: () =>
      import(
        /* webpackChunkName: "events-task-list" */
        '../views/Event/Events.vue'
      )
  },
  {
    path: '/events/add',
    name: 'events-add',
    component: () => import('../views/Event/add')
  },
  {
    path: '/events/edit',
    name: 'events-edit',
    component: () => import('../views/Event/edit')
  },
  {
    path: '/events/view',
    name: 'events-view',
    component: () =>
      import(
        /* webpackChunkName: "multi-assign-group" */
        '../views/Event/EventView.vue'
      )
  },
  {
    path: '/events/tui-calendar',
    name: 'tui-calendar',
    meta: {
      calendar: true
    },
    component: () => import('../views/Event/TUICalendar.vue')
  },
  {
    path: '/agenda',
    name: 'agenda',
    meta: {
      permissions: ['v1.agenda.index']
    },
    component: () =>
      import(
        /* webpackChunkName: "events-task-list" */
        '../views/Agenda/Agenda.vue'
      )
  },
  {
    path: '/agenda/add',
    name: 'agenda-add',
    meta: {
      permissions: ['v1.agenda.create']
    },
    component: () =>
      import(
        /* webpackChunkName: "events-task-list" */
        '../views/Agenda/AgendaAdd.vue'
      )
  },
  {
    path: '/agenda/edit',
    meta: {
      permissions: ['v1.agenda.update']
    },
    name: 'agenda-edit',
    component: () =>
      import(
        /* webpackChunkName: "events-task-list" */
        '../views/Agenda/AgendaEdit.vue'
      )
  },
  {
    path: '/agenda/view',
    meta: {
      permissions: ['v1.agenda.index']
    },
    name: 'agenda-view',
    component: () =>
      import(
        /* webpackChunkName: "events-task-list" */
        '../views/Agenda/AgendaView.vue'
      )
  },
  {
    path: '/category',
    name: 'category',
    meta: {
      permissions: ['v1.category.index']
    },
    component: () =>
      import(
        /* webpackChunkName: "events-task-list" */
        '../views/Category/Category.vue'
      )
  },
  {
    path: '/category/add',
    name: 'category-add',
    meta: {
      permissions: ['v1.category.create']
    },
    component: () =>
      import(
        /* webpackChunkName: "events-task-list" */
        '../views/Category/CategoryAdd.vue'
      )
  },
  {
    path: '/category/view',
    name: 'category-view',
    meta: {
      permissions: ['v1.category.view']
    },
    component: () =>
      import(
        /* webpackChunkName: "events-task-list" */
        '../views/Category/CategoryView.vue'
      )
  },
  {
    path: '/events/ical',
    name: 'tui-ical',
    component: () =>
      import(
        /* webpackChunkName: "multi-assign-group" */
        '../views/Event/ICal.vue'
      )
  },

  {
    path: '/qrcode',
    name: 'qrcode',
    component: () =>
      import(
        /* webpackChunkName: "resetpassword" */
        '../views/QRCode/QRCodes.vue'
      )
  },
  {
    path: '/qrcode/list',
    name: 'qrcode-list',
    component: () =>
      import(
        /* webpackChunkName: "information-edit" */
        '../views/QRCode/QRCodes.vue'
      )
  },
  {
    path: '/qrcode/add',
    name: 'qrcode-add',
    component: () =>
      import(
        /* webpackChunkName: "information-edit" */
        '../views/QRCode/Generate.vue'
      )
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.VUE_APP_BASE_ROUTE),
  routes
});

router.beforeEach((to, from, next) => {
  updateCookieExpiration();
  const publicPages = [
    'public-connect',
    'public-home',
    'public-register',
    'public-changepassword',
    'public-resetpassword',
    'public-terms',
    'public-section1',
    'public-prices'
  ];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem('loggedIn');

  if (authRequired && !loggedIn) {
    next('/public/home');
  } else {
    next();
  }
});

export default router;