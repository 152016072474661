export const items = [
  {
    _id: '250',
    code_iso2: 'FR',
    code_iso3: 'FRA',
    name: 'France',
    prefix_phone: '33',
    favorit: true,
  },
  {
    _id: '56',
    code_iso2: 'BE',
    code_iso3: 'BEL',
    name: 'Belgique',
    prefix_phone: '32',
    favorit: true,
  },
  {
    _id: '528',
    code_iso2: 'NL',
    code_iso3: 'NLD',
    name: 'Pays-Bas',
    prefix_phone: '31',
    favorit: true,
  },
  {
    _id: '276',
    code_iso2: 'DE',
    code_iso3: 'DEU',
    name: 'Allemagne',
    prefix_phone: '49',
    favorit: true,
  },
  {
    _id: '826',
    code_iso2: 'GB',
    code_iso3: 'GBR',
    name: 'Royaume-Uni',
    prefix_phone: '44',
    favorit: true,
  },
  {
    _id: '724',
    code_iso2: 'ES',
    code_iso3: 'ESP',
    name: 'Espagne',
    prefix_phone: '34',
    favorit: true,
  },
  {
    _id: '380',
    code_iso2: 'IT',
    code_iso3: 'ITA',
    name: 'Italie',
    prefix_phone: '39',
    favorit: true,
  },
  {
    _id: '8',
    code_iso2: 'AL',
    code_iso3: 'ALB',
    name: 'Albanie',
    prefix_phone: ''
  },

  {
    _id: '10',
    code_iso2: 'AQ',
    code_iso3: 'ATA',
    name: 'Antarctique',
    prefix_phone: ''
  },
  {
    _id: '12',
    code_iso2: 'DZ',
    code_iso3: 'DZA',
    name: 'Algérie',
    prefix_phone: '213'
  },
  {
    _id: '16',
    code_iso2: 'AS',
    code_iso3: 'ASM',
    name: 'Samoa Américaines',
    prefix_phone: ''
  },
  {
    _id: '20',
    code_iso2: 'AD',
    code_iso3: 'AND',
    name: 'Andorre',
    prefix_phone: '376'
  },
  {
    _id: '24',
    code_iso2: 'AO',
    code_iso3: 'AGO',
    name: 'Angola',
    prefix_phone: '244'
  },
  {
    _id: '28',
    code_iso2: 'AG',
    code_iso3: 'ATG',
    name: 'Antigua-et-Barbuda',
    prefix_phone: '1268'
  },
  {
    _id: '31',
    code_iso2: 'AZ',
    code_iso3: 'AZE',
    name: 'Azerbaïdjan',
    prefix_phone: '994'
  },
  {
    _id: '32',
    code_iso2: 'AR',
    code_iso3: 'ARG',
    name: 'Argentine',
    prefix_phone: '54'
  },
  {
    _id: '36',
    code_iso2: 'AU',
    code_iso3: 'AUS',
    name: 'Australie',
    prefix_phone: '61'
  },
  {
    _id: '40',
    code_iso2: 'AT',
    code_iso3: 'AUT',
    name: 'Autriche',
    prefix_phone: '43'
  },
  {
    _id: '44',
    code_iso2: 'BS',
    code_iso3: 'BHS',
    name: 'Bahamas',
    prefix_phone: '1242'
  },
  {
    _id: '48',
    code_iso2: 'BH',
    code_iso3: 'BHR',
    name: 'Bahreïn',
    prefix_phone: '973'
  },
  {
    _id: '50',
    code_iso2: 'BD',
    code_iso3: 'BGD',
    name: 'Bangladesh',
    prefix_phone: '880'
  },
  {
    _id: '51',
    code_iso2: 'AM',
    code_iso3: 'ARM',
    name: 'Arménie',
    prefix_phone: '374'
  },
  {
    _id: '52',
    code_iso2: 'BB',
    code_iso3: 'BRB',
    name: 'Barbade',
    prefix_phone: '1246'
  },
  {
    _id: '60',
    code_iso2: 'BM',
    code_iso3: 'BMU',
    name: 'Bermudes',
    prefix_phone: '1441'
  },
  {
    _id: '64',
    code_iso2: 'BT',
    code_iso3: 'BTN',
    name: 'Bhoutan',
    prefix_phone: '975'
  },
  {
    _id: '68',
    code_iso2: 'BO',
    code_iso3: 'BOL',
    name: 'Bolivie',
    prefix_phone: '591'
  },
  {
    _id: '70',
    code_iso2: 'BA',
    code_iso3: 'BIH',
    name: 'Bosnie-Herzégovine',
    prefix_phone: ''
  },
  {
    _id: '72',
    code_iso2: 'BW',
    code_iso3: 'BWA',
    name: 'Botswana',
    prefix_phone: ''
  },
  {
    _id: '74',
    code_iso2: 'BV',
    code_iso3: 'BVT',
    name: 'Île Bouvet',
    prefix_phone: ''
  },
  {
    _id: '76',
    code_iso2: 'BR',
    code_iso3: 'BRA',
    name: 'Brésil',
    prefix_phone: '55'
  },
  {
    _id: '84',
    code_iso2: 'BZ',
    code_iso3: 'BLZ',
    name: 'Belize',
    prefix_phone: '501'
  },
  {
    _id: '86',
    code_iso2: 'IO',
    code_iso3: 'IOT',
    name: 'Territoire Britannique de lOcéan Indien',
    prefix_phone: ''
  },
  {
    _id: '90',
    code_iso2: 'SB',
    code_iso3: 'SLB',
    name: 'Îles Salomon',
    prefix_phone: ''
  },
  {
    _id: '92',
    code_iso2: 'VG',
    code_iso3: 'VGB',
    name: 'Îles Vierges Britanniques',
    prefix_phone: ''
  },
  {
    _id: '96',
    code_iso2: 'BN',
    code_iso3: 'BRN',
    name: 'Brunéi Darussalam',
    prefix_phone: ''
  },
  {
    _id: '100',
    code_iso2: 'BG',
    code_iso3: 'BGR',
    name: 'Bulgarie',
    prefix_phone: '359'
  },
  {
    _id: '104',
    code_iso2: 'MM',
    code_iso3: 'MMR',
    name: 'Myanmar',
    prefix_phone: ''
  },
  {
    _id: '108',
    code_iso2: 'BI',
    code_iso3: 'BDI',
    name: 'Burundi',
    prefix_phone: ''
  },
  {
    _id: '112',
    code_iso2: 'BY',
    code_iso3: 'BLR',
    name: 'Bélarus',
    prefix_phone: ''
  },
  {
    _id: '116',
    code_iso2: 'KH',
    code_iso3: 'KHM',
    name: 'Cambodge',
    prefix_phone: '855'
  },
  {
    _id: '120',
    code_iso2: 'CM',
    code_iso3: 'CMR',
    name: 'Cameroun',
    prefix_phone: '237'
  },
  {
    _id: '124',
    code_iso2: 'CA',
    code_iso3: 'CAN',
    name: 'Canada',
    prefix_phone: '1'
  },
  {
    _id: '132',
    code_iso2: 'CV',
    code_iso3: 'CPV',
    name: 'Cap-vert',
    prefix_phone: '238'
  },
  {
    _id: '136',
    code_iso2: 'KY',
    code_iso3: 'CYM',
    name: 'Îles Caïmanes',
    prefix_phone: ''
  },
  {
    _id: '140',
    code_iso2: 'CF',
    code_iso3: 'CAF',
    name: 'République Centrafricaine',
    prefix_phone: ''
  },
  {
    _id: '144',
    code_iso2: 'LK',
    code_iso3: 'LKA',
    name: 'Sri Lanka',
    prefix_phone: ''
  },
  {
    _id: '148',
    code_iso2: 'TD',
    code_iso3: 'TCD',
    name: 'Tchad',
    prefix_phone: ''
  },
  {
    _id: '152',
    code_iso2: 'CL',
    code_iso3: 'CHL',
    name: 'Chili',
    prefix_phone: ''
  },
  {
    _id: '156',
    code_iso2: 'CN',
    code_iso3: 'CHN',
    name: 'Chine',
    prefix_phone: ''
  },
  {
    _id: '158',
    code_iso2: 'TW',
    code_iso3: 'TWN',
    name: 'Taïwan',
    prefix_phone: ''
  },
  {
    _id: '162',
    code_iso2: 'CX',
    code_iso3: 'CXR',
    name: 'Île Christmas',
    prefix_phone: ''
  },
  {
    _id: '166',
    code_iso2: 'CC',
    code_iso3: 'CCK',
    name: 'Îles Cocos Keeling',
    prefix_phone: ''
  },
  {
    _id: '170',
    code_iso2: 'CO',
    code_iso3: 'COL',
    name: 'Colombie',
    prefix_phone: ''
  },
  {
    _id: '174',
    code_iso2: 'KM',
    code_iso3: 'COM',
    name: 'Comores',
    prefix_phone: ''
  },
  {
    _id: '175',
    code_iso2: 'YT',
    code_iso3: 'MYT',
    name: 'Mayotte',
    prefix_phone: ''
  },
  {
    _id: '178',
    code_iso2: 'CG',
    code_iso3: 'COG',
    name: 'République du Congo',
    prefix_phone: ''
  },
  {
    _id: '180',
    code_iso2: 'CD',
    code_iso3: 'COD',
    name: 'République Démocratique du Congo',
    prefix_phone: ''
  },
  {
    _id: '184',
    code_iso2: 'CK',
    code_iso3: 'COK',
    name: 'Îles Cook',
    prefix_phone: ''
  },
  {
    _id: '188',
    code_iso2: 'CR',
    code_iso3: 'CRI',
    name: 'Costa Rica',
    prefix_phone: ''
  },
  {
    _id: '191',
    code_iso2: 'HR',
    code_iso3: 'HRV',
    name: 'Croatie',
    prefix_phone: ''
  },
  {
    _id: '192',
    code_iso2: 'CU',
    code_iso3: 'CUB',
    name: 'Cuba',
    prefix_phone: ''
  },
  {
    _id: '196',
    code_iso2: 'CY',
    code_iso3: 'CYP',
    name: 'Chypre',
    prefix_phone: ''
  },
  {
    _id: '203',
    code_iso2: 'CZ',
    code_iso3: 'CZE',
    name: 'République Tchèque',
    prefix_phone: ''
  },
  {
    _id: '204',
    code_iso2: 'BJ',
    code_iso3: 'BEN',
    name: 'Bénin',
    prefix_phone: '229'
  },
  {
    _id: '208',
    code_iso2: 'DK',
    code_iso3: 'DNK',
    name: 'Danemark',
    prefix_phone: ''
  },
  {
    _id: '212',
    code_iso2: 'DM',
    code_iso3: 'DMA',
    name: 'Dominique',
    prefix_phone: ''
  },
  {
    _id: '214',
    code_iso2: 'DO',
    code_iso3: 'DOM',
    name: 'République Dominicaine',
    prefix_phone: ''
  },
  {
    _id: '218',
    code_iso2: 'EC',
    code_iso3: 'ECU',
    name: 'Équateur',
    prefix_phone: ''
  },
  {
    _id: '222',
    code_iso2: 'SV',
    code_iso3: 'SLV',
    name: 'El Salvador',
    prefix_phone: ''
  },
  {
    _id: '226',
    code_iso2: 'GQ',
    code_iso3: 'GNQ',
    name: 'Guinée Équatoriale',
    prefix_phone: ''
  },
  {
    _id: '231',
    code_iso2: 'ET',
    code_iso3: 'ETH',
    name: 'Éthiopie',
    prefix_phone: ''
  },
  {
    _id: '232',
    code_iso2: 'ER',
    code_iso3: 'ERI',
    name: 'Érythrée',
    prefix_phone: ''
  },
  {
    _id: '233',
    code_iso2: 'EE',
    code_iso3: 'EST',
    name: 'Estonie',
    prefix_phone: ''
  },
  {
    _id: '234',
    code_iso2: 'FO',
    code_iso3: 'FRO',
    name: 'Îles Féroé',
    prefix_phone: ''
  },
  {
    _id: '238',
    code_iso2: 'FK',
    code_iso3: 'FLK',
    name: 'Îles malvinas Falkland',
    prefix_phone: ''
  },
  {
    _id: '239',
    code_iso2: 'GS',
    code_iso3: 'SGS',
    name: 'Géorgie du Sud et les Îles Sandwich du Sud',
    prefix_phone: ''
  },
  {
    _id: '242',
    code_iso2: 'FJ',
    code_iso3: 'FJI',
    name: 'Fidji',
    prefix_phone: ''
  },
  {
    _id: '246',
    code_iso2: 'FI',
    code_iso3: 'FIN',
    name: 'Finlande',
    prefix_phone: ''
  },
  {
    _id: '248',
    code_iso2: 'AX',
    code_iso3: 'ALA',
    name: 'Îles Åland',
    prefix_phone: ''
  },
  {
    _id: '4',
    code_iso2: 'AF',
    code_iso3: 'AFG',
    name: 'Afghanistan',
    prefix_phone: '93'
  },
  {
    _id: '254',
    code_iso2: 'GF',
    code_iso3: 'GUF',
    name: 'Guyane Française',
    prefix_phone: ''
  },
  {
    _id: '258',
    code_iso2: 'PF',
    code_iso3: 'PYF',
    name: 'Polynésie Française',
    prefix_phone: ''
  },
  {
    _id: '260',
    code_iso2: 'TF',
    code_iso3: 'ATF',
    name: 'Terres Australes Françaises',
    prefix_phone: ''
  },
  {
    _id: '262',
    code_iso2: 'DJ',
    code_iso3: 'DJI',
    name: 'Djibouti',
    prefix_phone: ''
  },
  {
    _id: '266',
    code_iso2: 'GA',
    code_iso3: 'GAB',
    name: 'Gabon',
    prefix_phone: ''
  },
  {
    _id: '268',
    code_iso2: 'GE',
    code_iso3: 'GEO',
    name: 'Géorgie',
    prefix_phone: ''
  },
  {
    _id: '270',
    code_iso2: 'GM',
    code_iso3: 'GMB',
    name: 'Gambie',
    prefix_phone: ''
  },
  {
    _id: '275',
    code_iso2: 'PS',
    code_iso3: 'PSE',
    name: 'Territoire Palestinien Occupé',
    prefix_phone: ''
  },
  {
    _id: '288',
    code_iso2: 'GH',
    code_iso3: 'GHA',
    name: 'Ghana',
    prefix_phone: ''
  },
  {
    _id: '292',
    code_iso2: 'GI',
    code_iso3: 'GIB',
    name: 'Gibraltar',
    prefix_phone: ''
  },
  {
    _id: '296',
    code_iso2: 'KI',
    code_iso3: 'KIR',
    name: 'Kiribati',
    prefix_phone: '686'
  },
  {
    _id: '300',
    code_iso2: 'GR',
    code_iso3: 'GRC',
    name: 'Grèce',
    prefix_phone: ''
  },
  {
    _id: '304',
    code_iso2: 'GL',
    code_iso3: 'GRL',
    name: 'Groenland',
    prefix_phone: ''
  },
  {
    _id: '308',
    code_iso2: 'GD',
    code_iso3: 'GRD',
    name: 'Grenade',
    prefix_phone: ''
  },
  {
    _id: '312',
    code_iso2: 'GP',
    code_iso3: 'GLP',
    name: 'Guadeloupe',
    prefix_phone: ''
  },
  {
    _id: '316',
    code_iso2: 'GU',
    code_iso3: 'GUM',
    name: 'Guam',
    prefix_phone: ''
  },
  {
    _id: '320',
    code_iso2: 'GT',
    code_iso3: 'GTM',
    name: 'Guatemala',
    prefix_phone: ''
  },
  {
    _id: '324',
    code_iso2: 'GN',
    code_iso3: 'GIN',
    name: 'Guinée',
    prefix_phone: ''
  },
  {
    _id: '328',
    code_iso2: 'GY',
    code_iso3: 'GUY',
    name: 'Guyana',
    prefix_phone: ''
  },
  {
    _id: '332',
    code_iso2: 'HT',
    code_iso3: 'HTI',
    name: 'Haïti',
    prefix_phone: ''
  },
  {
    _id: '334',
    code_iso2: 'HM',
    code_iso3: 'HMD',
    name: 'Îles Heard et Mcdonald',
    prefix_phone: ''
  },
  {
    _id: '336',
    code_iso2: 'VA',
    code_iso3: 'VAT',
    name: 'Saint-Siège état de la Cité du Vatican',
    prefix_phone: ''
  },
  {
    _id: '340',
    code_iso2: 'HN',
    code_iso3: 'HND',
    name: 'Honduras',
    prefix_phone: ''
  },
  {
    _id: '344',
    code_iso2: 'HK',
    code_iso3: 'HKG',
    name: 'Hong-Kong',
    prefix_phone: ''
  },
  {
    _id: '348',
    code_iso2: 'HU',
    code_iso3: 'HUN',
    name: 'Hongrie',
    prefix_phone: ''
  },
  {
    _id: '352',
    code_iso2: 'IS',
    code_iso3: 'ISL',
    name: 'Islande',
    prefix_phone: '354'
  },
  {
    _id: '356',
    code_iso2: 'IN',
    code_iso3: 'IND',
    name: 'Inde',
    prefix_phone: '91'
  },
  {
    _id: '360',
    code_iso2: 'ID',
    code_iso3: 'IDN',
    name: 'Indonésie',
    prefix_phone: '62'
  },
  {
    _id: '364',
    code_iso2: 'IR',
    code_iso3: 'IRN',
    name: 'République Islamique dIran',
    prefix_phone: ''
  },
  {
    _id: '368',
    code_iso2: 'IQ',
    code_iso3: 'IRQ',
    name: 'Iraq',
    prefix_phone: '964'
  },
  {
    _id: '372',
    code_iso2: 'IE',
    code_iso3: 'IRL',
    name: 'Irlande',
    prefix_phone: '353'
  },
  {
    _id: '376',
    code_iso2: 'IL',
    code_iso3: 'ISR',
    name: 'Israël',
    prefix_phone: '972'
  },
  {
    _id: '384',
    code_iso2: 'CI',
    code_iso3: 'CIV',
    name: 'Côte dIvoire',
    prefix_phone: '225'
  },
  {
    _id: '388',
    code_iso2: 'JM',
    code_iso3: 'JAM',
    name: 'Jamaïque',
    prefix_phone: '62'
  },
  {
    _id: '392',
    code_iso2: 'JP',
    code_iso3: 'JPN',
    name: 'Japon',
    prefix_phone: '81'
  },
  {
    _id: '398',
    code_iso2: 'KZ',
    code_iso3: 'KAZ',
    name: 'Kazakhstan',
    prefix_phone: '76'
  },
  {
    _id: '400',
    code_iso2: 'JO',
    code_iso3: 'JOR',
    name: 'Jordanie',
    prefix_phone: '962'
  },
  {
    _id: '404',
    code_iso2: 'KE',
    code_iso3: 'KEN',
    name: 'Kenya',
    prefix_phone: '254'
  },
  {
    _id: '408',
    code_iso2: 'KP',
    code_iso3: 'PRK',
    name: 'République Populaire Démocratique de Corée',
    prefix_phone: ''
  },
  {
    _id: '410',
    code_iso2: 'KR',
    code_iso3: 'KOR',
    name: 'République de Corée',
    prefix_phone: ''
  },
  {
    _id: '414',
    code_iso2: 'KW',
    code_iso3: 'KWT',
    name: 'Koweït',
    prefix_phone: '965'
  },
  {
    _id: '417',
    code_iso2: 'KG',
    code_iso3: 'KGZ',
    name: 'Kirghizistan',
    prefix_phone: '996'
  },
  {
    _id: '418',
    code_iso2: 'LA',
    code_iso3: 'LAO',
    name: 'République Démocratique Populaire Lao',
    prefix_phone: ''
  },
  {
    _id: '422',
    code_iso2: 'LB',
    code_iso3: 'LBN',
    name: 'Liban',
    prefix_phone: '961'
  },
  {
    _id: '426',
    code_iso2: 'LS',
    code_iso3: 'LSO',
    name: 'Lesotho',
    prefix_phone: '266'
  },
  {
    _id: '428',
    code_iso2: 'LV',
    code_iso3: 'LVA',
    name: 'Lettonie',
    prefix_phone: '371'
  },
  {
    _id: '430',
    code_iso2: 'LR',
    code_iso3: 'LBR',
    name: 'Libéria',
    prefix_phone: '231'
  },
  {
    _id: '434',
    code_iso2: 'LY',
    code_iso3: 'LBY',
    name: 'Jamahiriya Arabe Libyenne',
    prefix_phone: '1876'
  },
  {
    _id: '438',
    code_iso2: 'LI',
    code_iso3: 'LIE',
    name: 'Liechtenstein',
    prefix_phone: '423'
  },
  {
    _id: '440',
    code_iso2: 'LT',
    code_iso3: 'LTU',
    name: 'Lituanie',
    prefix_phone: '370'
  },
  {
    _id: '442',
    code_iso2: 'LU',
    code_iso3: 'LUX',
    name: 'Luxembourg',
    prefix_phone: '352'
  },
  {
    _id: '446',
    code_iso2: 'MO',
    code_iso3: 'MAC',
    name: 'Macao',
    prefix_phone: ''
  },
  {
    _id: '450',
    code_iso2: 'MG',
    code_iso3: 'MDG',
    name: 'Madagascar',
    prefix_phone: ''
  },
  {
    _id: '454',
    code_iso2: 'MW',
    code_iso3: 'MWI',
    name: 'Malawi',
    prefix_phone: ''
  },
  {
    _id: '458',
    code_iso2: 'MY',
    code_iso3: 'MYS',
    name: 'Malaisie',
    prefix_phone: ''
  },
  {
    _id: '462',
    code_iso2: 'MV',
    code_iso3: 'MDV',
    name: 'Maldives',
    prefix_phone: ''
  },
  {
    _id: '466',
    code_iso2: 'ML',
    code_iso3: 'MLI',
    name: 'Mali',
    prefix_phone: ''
  },
  {
    _id: '470',
    code_iso2: 'MT',
    code_iso3: 'MLT',
    name: 'Malte',
    prefix_phone: ''
  },
  {
    _id: '474',
    code_iso2: 'MQ',
    code_iso3: 'MTQ',
    name: 'Martinique',
    prefix_phone: ''
  },
  {
    _id: '478',
    code_iso2: 'MR',
    code_iso3: 'MRT',
    name: 'Mauritanie',
    prefix_phone: ''
  },
  {
    _id: '480',
    code_iso2: 'MU',
    code_iso3: 'MUS',
    name: 'Maurice',
    prefix_phone: ''
  },
  {
    _id: '484',
    code_iso2: 'MX',
    code_iso3: 'MEX',
    name: 'Mexique',
    prefix_phone: ''
  },
  {
    _id: '492',
    code_iso2: 'MC',
    code_iso3: 'MCO',
    name: 'Monaco',
    prefix_phone: ''
  },
  {
    _id: '496',
    code_iso2: 'MN',
    code_iso3: 'MNG',
    name: 'Mongolie',
    prefix_phone: ''
  },
  {
    _id: '498',
    code_iso2: 'MD',
    code_iso3: 'MDA',
    name: 'République de Moldova',
    prefix_phone: ''
  },
  {
    _id: '500',
    code_iso2: 'MS',
    code_iso3: 'MSR',
    name: 'Montserrat',
    prefix_phone: ''
  },
  {
    _id: '504',
    code_iso2: 'MA',
    code_iso3: 'MAR',
    name: 'Maroc',
    prefix_phone: '212'
  },
  {
    _id: '508',
    code_iso2: 'MZ',
    code_iso3: 'MOZ',
    name: 'Mozambique',
    prefix_phone: ''
  },
  {
    _id: '512',
    code_iso2: 'OM',
    code_iso3: 'OMN',
    name: 'Oman',
    prefix_phone: ''
  },
  {
    _id: '516',
    code_iso2: 'NA',
    code_iso3: 'NAM',
    name: 'Namibie',
    prefix_phone: ''
  },
  {
    _id: '520',
    code_iso2: 'NR',
    code_iso3: 'NRU',
    name: 'Nauru',
    prefix_phone: ''
  },
  {
    _id: '524',
    code_iso2: 'NP',
    code_iso3: 'NPL',
    name: 'Népal',
    prefix_phone: ''
  },
  {
    _id: '530',
    code_iso2: 'AN',
    code_iso3: 'ANT',
    name: 'Antilles Néerlandaises',
    prefix_phone: ''
  },
  {
    _id: '533',
    code_iso2: 'AW',
    code_iso3: 'ABW',
    name: 'Aruba',
    prefix_phone: '297'
  },
  {
    _id: '540',
    code_iso2: 'NC',
    code_iso3: 'NCL',
    name: 'Nouvelle-Calédonie',
    prefix_phone: ''
  },
  {
    _id: '548',
    code_iso2: 'VU',
    code_iso3: 'VUT',
    name: 'Vanuatu',
    prefix_phone: ''
  },
  {
    _id: '554',
    code_iso2: 'NZ',
    code_iso3: 'NZL',
    name: 'Nouvelle-Zélande',
    prefix_phone: ''
  },
  {
    _id: '558',
    code_iso2: 'NI',
    code_iso3: 'NIC',
    name: 'Nicaragua',
    prefix_phone: ''
  },
  {
    _id: '562',
    code_iso2: 'NE',
    code_iso3: 'NER',
    name: 'Niger',
    prefix_phone: ''
  },
  {
    _id: '566',
    code_iso2: 'NG',
    code_iso3: 'NGA',
    name: 'Nigéria',
    prefix_phone: ''
  },
  {
    _id: '570',
    code_iso2: 'NU',
    code_iso3: 'NIU',
    name: 'Niué',
    prefix_phone: ''
  },
  {
    _id: '574',
    code_iso2: 'NF',
    code_iso3: 'NFK',
    name: 'Île Norfolk',
    prefix_phone: ''
  },
  {
    _id: '578',
    code_iso2: 'NO',
    code_iso3: 'NOR',
    name: 'Norvège',
    prefix_phone: ''
  },
  {
    _id: '580',
    code_iso2: 'MP',
    code_iso3: 'MNP',
    name: 'Îles Mariannes du Nord',
    prefix_phone: ''
  },
  {
    _id: '581',
    code_iso2: 'UM',
    code_iso3: 'UMI',
    name: 'Îles Mineures Éloignées des États-Unis',
    prefix_phone: ''
  },
  {
    _id: '583',
    code_iso2: 'FM',
    code_iso3: 'FSM',
    name: 'États Fédérés de Micronésie',
    prefix_phone: ''
  },
  {
    _id: '584',
    code_iso2: 'MH',
    code_iso3: 'MHL',
    name: 'Îles Marshall',
    prefix_phone: ''
  },
  {
    _id: '585',
    code_iso2: 'PW',
    code_iso3: 'PLW',
    name: 'Palaos',
    prefix_phone: ''
  },
  {
    _id: '586',
    code_iso2: 'PK',
    code_iso3: 'PAK',
    name: 'Pakistan',
    prefix_phone: ''
  },
  {
    _id: '591',
    code_iso2: 'PA',
    code_iso3: 'PAN',
    name: 'Panama',
    prefix_phone: ''
  },
  {
    _id: '598',
    code_iso2: 'PG',
    code_iso3: 'PNG',
    name: 'Papouasie-Nouvelle-Guinée',
    prefix_phone: ''
  },
  {
    _id: '600',
    code_iso2: 'PY',
    code_iso3: 'PRY',
    name: 'Paraguay',
    prefix_phone: ''
  },
  {
    _id: '604',
    code_iso2: 'PE',
    code_iso3: 'PER',
    name: 'Pérou',
    prefix_phone: ''
  },
  {
    _id: '608',
    code_iso2: 'PH',
    code_iso3: 'PHL',
    name: 'Philippines',
    prefix_phone: ''
  },
  {
    _id: '612',
    code_iso2: 'PN',
    code_iso3: 'PCN',
    name: 'Pitcairn',
    prefix_phone: ''
  },
  {
    _id: '616',
    code_iso2: 'PL',
    code_iso3: 'POL',
    name: 'Pologne',
    prefix_phone: ''
  },
  {
    _id: '620',
    code_iso2: 'PT',
    code_iso3: 'PRT',
    name: 'Portugal',
    prefix_phone: ''
  },
  {
    _id: '624',
    code_iso2: 'GW',
    code_iso3: 'GNB',
    name: 'Guinée-Bissau',
    prefix_phone: ''
  },
  {
    _id: '626',
    code_iso2: 'TL',
    code_iso3: 'TLS',
    name: 'Timor-Leste',
    prefix_phone: ''
  },
  {
    _id: '630',
    code_iso2: 'PR',
    code_iso3: 'PRI',
    name: 'Porto Rico',
    prefix_phone: ''
  },
  {
    _id: '634',
    code_iso2: 'QA',
    code_iso3: 'QAT',
    name: 'Qatar',
    prefix_phone: ''
  },
  {
    _id: '638',
    code_iso2: 'RE',
    code_iso3: 'REU',
    name: 'Réunion',
    prefix_phone: ''
  },
  {
    _id: '642',
    code_iso2: 'RO',
    code_iso3: 'ROU',
    name: 'Roumanie',
    prefix_phone: ''
  },
  {
    _id: '643',
    code_iso2: 'RU',
    code_iso3: 'RUS',
    name: 'Fédération de Russie',
    prefix_phone: ''
  },
  {
    _id: '646',
    code_iso2: 'RW',
    code_iso3: 'RWA',
    name: 'Rwanda',
    prefix_phone: ''
  },
  {
    _id: '654',
    code_iso2: 'SH',
    code_iso3: 'SHN',
    name: 'Sainte-Hélène',
    prefix_phone: ''
  },
  {
    _id: '659',
    code_iso2: 'KN',
    code_iso3: 'KNA',
    name: 'Saint-Kitts-et-Nevis',
    prefix_phone: ''
  },
  {
    _id: '660',
    code_iso2: 'AI',
    code_iso3: 'AIA',
    name: 'Anguilla',
    prefix_phone: '1264'
  },
  {
    _id: '662',
    code_iso2: 'LC',
    code_iso3: 'LCA',
    name: 'Sainte-Lucie',
    prefix_phone: ''
  },
  {
    _id: '666',
    code_iso2: 'PM',
    code_iso3: 'SPM',
    name: 'Saint-Pierre-et-Miquelon',
    prefix_phone: ''
  },
  {
    _id: '670',
    code_iso2: 'VC',
    code_iso3: 'VCT',
    name: 'Saint-Vincent-et-les Grenadines',
    prefix_phone: ''
  },
  {
    _id: '674',
    code_iso2: 'SM',
    code_iso3: 'SMR',
    name: 'Saint-Marin',
    prefix_phone: ''
  },
  {
    _id: '678',
    code_iso2: 'ST',
    code_iso3: 'STP',
    name: 'Sao Tomé-et-Principe',
    prefix_phone: ''
  },
  {
    _id: '682',
    code_iso2: 'SA',
    code_iso3: 'SAU',
    name: 'Arabie Saoudite',
    prefix_phone: '966'
  },
  {
    _id: '686',
    code_iso2: 'SN',
    code_iso3: 'SEN',
    name: 'Sénégal',
    prefix_phone: '221'
  },
  {
    _id: '690',
    code_iso2: 'SC',
    code_iso3: 'SYC',
    name: 'Seychelles',
    prefix_phone: ''
  },
  {
    _id: '694',
    code_iso2: 'SL',
    code_iso3: 'SLE',
    name: 'Sierra Leone',
    prefix_phone: ''
  },
  {
    _id: '702',
    code_iso2: 'SG',
    code_iso3: 'SGP',
    name: 'Singapour',
    prefix_phone: ''
  },
  {
    _id: '703',
    code_iso2: 'SK',
    code_iso3: 'SVK',
    name: 'Slovaquie',
    prefix_phone: ''
  },
  {
    _id: '704',
    code_iso2: 'VN',
    code_iso3: 'VNM',
    name: 'Viet Nam',
    prefix_phone: ''
  },
  {
    _id: '705',
    code_iso2: 'SI',
    code_iso3: 'SVN',
    name: 'Slovénie',
    prefix_phone: ''
  },
  {
    _id: '706',
    code_iso2: 'SO',
    code_iso3: 'SOM',
    name: 'Somalie',
    prefix_phone: ''
  },
  {
    _id: '710',
    code_iso2: 'ZA',
    code_iso3: 'ZAF',
    name: 'Afrique du Sud',
    prefix_phone: '27'
  },
  {
    _id: '716',
    code_iso2: 'ZW',
    code_iso3: 'ZWE',
    name: 'Zimbabwe',
    prefix_phone: ''
  },
  {
    _id: '736',
    code_iso2: 'SD',
    code_iso3: 'SDN',
    name: 'Soudan',
    prefix_phone: ''
  },
  {
    _id: '740',
    code_iso2: 'SR',
    code_iso3: 'SUR',
    name: 'Suriname',
    prefix_phone: ''
  },
  {
    _id: '744',
    code_iso2: 'SJ',
    code_iso3: 'SJM',
    name: 'Svalbard etÎle Jan Mayen',
    prefix_phone: ''
  },
  {
    _id: '748',
    code_iso2: 'SZ',
    code_iso3: 'SWZ',
    name: 'Swaziland',
    prefix_phone: ''
  },
  {
    _id: '752',
    code_iso2: 'SE',
    code_iso3: 'SWE',
    name: 'Suède',
    prefix_phone: ''
  },
  {
    _id: '756',
    code_iso2: 'CH',
    code_iso3: 'CHE',
    name: 'Suisse',
    prefix_phone: ''
  },
  {
    _id: '760',
    code_iso2: 'SY',
    code_iso3: 'SYR',
    name: 'République Arabe Syrienne',
    prefix_phone: ''
  },
  {
    _id: '762',
    code_iso2: 'TJ',
    code_iso3: 'TJK',
    name: 'Tadjikistan',
    prefix_phone: ''
  },
  {
    _id: '764',
    code_iso2: 'TH',
    code_iso3: 'THA',
    name: 'Thaïlande',
    prefix_phone: ''
  },
  {
    _id: '768',
    code_iso2: 'TG',
    code_iso3: 'TGO',
    name: 'Togo',
    prefix_phone: ''
  },
  {
    _id: '772',
    code_iso2: 'TK',
    code_iso3: 'TKL',
    name: 'Tokelau',
    prefix_phone: ''
  },
  {
    _id: '776',
    code_iso2: 'TO',
    code_iso3: 'TON',
    name: 'Tonga',
    prefix_phone: ''
  },
  {
    _id: '780',
    code_iso2: 'TT',
    code_iso3: 'TTO',
    name: 'Trinité-et-Tobago',
    prefix_phone: ''
  },
  {
    _id: '784',
    code_iso2: 'AE',
    code_iso3: 'ARE',
    name: 'Émirats Arabes Unis',
    prefix_phone: ''
  },
  {
    _id: '788',
    code_iso2: 'TN',
    code_iso3: 'TUN',
    name: 'Tunisie',
    prefix_phone: '216'
  },
  {
    _id: '792',
    code_iso2: 'TR',
    code_iso3: 'TUR',
    name: 'Turquie',
    prefix_phone: ''
  },
  {
    _id: '795',
    code_iso2: 'TM',
    code_iso3: 'TKM',
    name: 'Turkménistan',
    prefix_phone: ''
  },
  {
    _id: '796',
    code_iso2: 'TC',
    code_iso3: 'TCA',
    name: 'Îles Turks et Caïques',
    prefix_phone: ''
  },
  {
    _id: '798',
    code_iso2: 'TV',
    code_iso3: 'TUV',
    name: 'Tuvalu',
    prefix_phone: ''
  },
  {
    _id: '800',
    code_iso2: 'UG',
    code_iso3: 'UGA',
    name: 'Ouganda',
    prefix_phone: ''
  },
  {
    _id: '804',
    code_iso2: 'UA',
    code_iso3: 'UKR',
    name: 'Ukraine',
    prefix_phone: '',
  },
  {
    _id: '807',
    code_iso2: 'MK',
    code_iso3: 'MKD',
    name: 'Lex-République Yougoslave de Macédoine',
    prefix_phone: ''
  },
  {
    _id: '818',
    code_iso2: 'EG',
    code_iso3: 'EGY',
    name: 'Égypte',
    prefix_phone: ''
  },
  {
    _id: '833',
    code_iso2: 'IM',
    code_iso3: 'IMN',
    name: 'Île de Man',
    prefix_phone: ''
  },
  {
    _id: '834',
    code_iso2: 'TZ',
    code_iso3: 'TZA',
    name: 'République-Unie de Tanzanie',
    prefix_phone: ''
  },
  {
    _id: '840',
    code_iso2: 'US',
    code_iso3: 'USA',
    name: 'États-Unis',
    prefix_phone: ''
  },
  {
    _id: '850',
    code_iso2: 'VI',
    code_iso3: 'VIR',
    name: 'Îles Vierges des États-Unis',
    prefix_phone: ''
  },
  {
    _id: '854',
    code_iso2: 'BF',
    code_iso3: 'BFA',
    name: 'Burkina Faso',
    prefix_phone: ''
  },
  {
    _id: '858',
    code_iso2: 'UY',
    code_iso3: 'URY',
    name: 'Uruguay',
    prefix_phone: ''
  },
  {
    _id: '860',
    code_iso2: 'UZ',
    code_iso3: 'UZB',
    name: 'Ouzbékistan',
    prefix_phone: ''
  },
  {
    _id: '862',
    code_iso2: 'VE',
    code_iso3: 'VEN',
    name: 'Venezuela',
    prefix_phone: ''
  },
  {
    _id: '876',
    code_iso2: 'WF',
    code_iso3: 'WLF',
    name: 'Wallis et Futuna',
    prefix_phone: ''
  },
  {
    _id: '882',
    code_iso2: 'WS',
    code_iso3: 'WSM',
    name: 'Samoa',
    prefix_phone: ''
  },
  {
    _id: '887',
    code_iso2: 'YE',
    code_iso3: 'YEM',
    name: 'Yémen',
    prefix_phone: ''
  },
  {
    _id: '891',
    code_iso2: 'CS',
    code_iso3: 'SCG',
    name: 'Serbie-et-Monténégro',
    prefix_phone: ''
  },
  {
    _id: '894',
    code_iso2: 'ZM',
    code_iso3: 'ZMB',
    name: 'Zambie',
    prefix_phone: ''
  }
];

export const countries = sort();

function sort() {
  const favorit = [];
  const other = [];
  items.forEach((el) => {
    if (el.favorit) {
      favorit.push(el);
    } else {
      other.push(el);
    }
  });
  return [...favorit, ...other];
}
