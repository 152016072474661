<template>
  <form class="register-form address-fields" ref="addressField">
    <p class="font-semibold p-mt-2">{{ i18n.$t('Fill your address') }}</p>
    <div class="form-input">
      <p class="p-mt-2">{{ i18n.$t('Country') }}</p>
      <Dropdown
        id="country"
        :options="countryGroups"
        v-model="countryValue"
        class="border border-gray-500 focus:border-main rounded p-1"
        style="width: 100%"
        optionLabel="label"
        optionGroupLabel="label"
        optionGroupChildren="items"
        @change="selected_code_iso2 = countryValue.code_iso2"
      >
        <template #value="slotProps">
          <div
            class="country-item country-item-value"
            v-if="slotProps.value && slotProps.value.code_iso2"
          >
            <!-- <img src="../../assets/images/flag_placeholder.png" :class="'flag flag-' + slotProps.value.code.toLowerCase()" /> -->
            <span :class="'flag flag-' + selected_code_iso2.toLowerCase()" />
            <div>{{ slotProps.value.name }}</div>
          </div>
          <span v-else class="country-item country-item-value">
            <span class="flag flag-fr" />
            <div>{{ prefixCode }} France</div></span
          >
        </template>
        <template #option="slotProps">
          <div class="country-item">
            <span
              :class="'flag flag-' + slotProps.option.code_iso2.toLowerCase()"
            />
            <div>
              {{ slotProps.option.name }}
            </div>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="form-input p-mt-2">
      <p class="">{{ i18n.$t('Address') }}</p>
      <AutoComplete
        v-model="address.addressReq"
        placeholder="Fill your company address"
        :suggestions="filteredCountrie"
        @complete="searchCountry($event)"
        field="label"
        @blur="validatingFullAddress()"
        class="border border-gray-500 focus:border-main rounded p-1"
        style="width: 100%"
        :disabled="!countryValue"
      />
      <div class="d-flex">
        <div class="form-input p-mt-4 btn-submit">
          <Button
            type="button"
            class="p-button-warning loading-btn"
            @click="init()"
          >
            <span>{{ i18n.$t('Init') }}</span>
          </Button>
        </div>
        <div class="form-input p-mt-4 btn-submit">
          <Button
            type="button"
            class="p-button-light loading-btn"
            @click="confirm()"
          >
            <span>{{ i18n.$t('Confirm') }}</span>
          </Button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { countries } from '../../store/data/countries';
import useI18n from '@/plugins/useI18n';
import axios from '@/plugins/axios';
import { ref } from 'vue';

export default {
  props: {
    address: {
      type: Object
    },
    country: {
      type: Object
    }
  },
  setup(props, { emit }) {
    const { i18n } = useI18n();

    const filteredCountries = ref([]);

    const countryValue = ref(null);

    countryValue.value = props.country ? props.country : 'France';

    const filteredCountrie = ref(null);

    const selected_code_iso2 = ref('FR');

    const countryGroups = ref([
      {
        label: 'Favorit',
        items: []
      },
      {
        label: i18n.$t('Other'),
        items: []
      }
    ]);

    groupUp();

    function groupUp() {
      countries.forEach((el) => {
        if (el.favorit) {
          countryGroups.value[0].items.push(el);
        } else {
          countryGroups.value[1].items.push(el);
        }
      });
    }

    function init(){
      props.address.addressReq = ''
    }

    function confirm(){
      emit('confirm', props.address.addressReq);
    }

    function searchCountry(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
        } else {
          axios
            .get(`tools/address?q=${event.query.trim()}`)
            .then((response) => {
              const { data } = response.data;

              for (let i = 0; i <= data.items[0].features.length; i++) {
                if (data.items[0].features[i] != undefined) {
                  var addressBar = data.items[0].features[i].properties;
                  filteredCountries.value.push(addressBar);
                }
              }
              if (filteredCountries.value != undefined) {
                filteredCountrie.value = filteredCountries.value.filter((i) => {
                  var alladress =
                    i.label.toLowerCase() + i.context.toLowerCase();

                  return alladress;
                });
              }
            });
        }
      }, 250);
      filteredCountries.value = [];
    }

    const autocomplete = ref(null);

    function validatingFullAddress() {
      if (props.address.addressReq['label']) {
        props.address.fullAddress = `${props.address.addressReq['label']} - ${props.address.addressReq['context']}`;
      }
      props.country = countryValue.value.name;
      emit('selected', props.address.fullAddress);
    }

    return {
      i18n,
      countries,
      validatingFullAddress,
      searchCountry,
      filteredCountries,
      filteredCountrie,
      autocomplete,
      countryValue,
      selected_code_iso2,
      countryGroups,
      init,
      confirm
    };
  }
};
</script>
<style>
.p-autocomplete-input {
  width: 100%;
}
.country-item {
  display: flex;
  align-items: center;
}
.d-flex{
  display: flex;
  justify-content: space-around;
}
</style>