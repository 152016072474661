<template>
  <TabView class="vertical-tabs p-mt-3">
    <TabPanel
      :header="item.title"
      v-for="item in tabs"
      :key="item.title"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    >
      <div class="card">
        <DataTable
          :value="item.content.items"
          stripedRows
          :paginator="true"
          :rows="10"
        >
          <Column
            :field="el.field"
            :header="el.header"
            v-for="el in item.content.header"
            :key="el.field"
          ></Column>
        </DataTable>
      </div>
    </TabPanel>
  </TabView>
</template>
<script>
import { ref } from 'vue';
import Spinner from '@/components/UI/Spinner.vue';
import useI18n from '@/plugins/useI18n';

export default {
  data() {
    return {};
  },
  props: {
    settings: {
      type: Object
    }
  },
  components: {
    Spinner
  },
  setup(props) {
    const { i18n } = useI18n();

    const tabs = [
      {
        title: i18n.$t('Events'),
        content: {
          header: [
            {
              field: 'code',
              header: 'Code1'
            },
            {
              field: 'name1',
              header: 'Name1'
            },
            {
              field: 'name2',
              header: 'Name1'
            },
            {
              field: 'name3',
              header: 'Name1'
            },
            {
              field: 'name4',
              header: 'Name1'
            }
          ],
          items: [
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            },
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            },
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            },
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            },
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            }
          ]
        }
      },
      {
        title: i18n.$t('Users'),
        content: {
          header: [
            {
              field: 'code',
              header: 'Code1'
            },
            {
              field: 'name1',
              header: 'Name1'
            },
            {
              field: 'name2',
              header: 'Name1'
            },
            {
              field: 'name3',
              header: 'Name1'
            },
            {
              field: 'name4',
              header: 'Name1'
            }
          ],
          items: [
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            },
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            },
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            },
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            },
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            }
          ]
        }
      },
      {
        title: i18n.$t('Information'),
        content: {
          header: [
            {
              field: 'code',
              header: 'Code1'
            },
            {
              field: 'name1',
              header: 'Name1'
            },
            {
              field: 'name2',
              header: 'Name1'
            },
            {
              field: 'name3',
              header: 'Name1'
            },
            {
              field: 'name4',
              header: 'Name1'
            }
          ],
          items: [
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            },
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            },
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            },
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            },
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            },
            {
              code: '1',
              name1: '1',
              name2: '1',
              name3: '1',
              name4: '1'
            }
          ]
        }
      }
    ];

    return {
      i18n,
      tabs
    };
  }
};
</script>

<style lang="scss">
.vertical-tabs.p-tabview {
  display: flex;
  flex-direction: row !important;
  .card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    .table {
      th {
        background: none;
      }
    }
  }
  .p-tabview-panels {
    padding: 0 !important;
  }
  .p-tabview-nav {
    flex-direction: column !important;
    margin-top: 10px;
    // min-width: 100px;
    border-color: transparent;
    li {
      padding-bottom: 3px;
    }
  }
}
</style>