import { SET_LANGUAGE, GET_LANGUAGE } from './types';

const lang = localStorage.getItem('lang');
const initialLang = lang
  ? lang
  : 'fr';
const state = {
  selectedLang: initialLang
};

const mutations = {
  [SET_LANGUAGE](state, payload) {
    state.selectedLang = payload;
  }
};

const getters = {
  [GET_LANGUAGE]: (state) => {
    return state.selectedLang;
  }
};

export default {
  state,
  mutations,
  getters
};
