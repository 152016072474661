<template>
  <header class="public-header">
    <div class="top-bar">
      <div class="top-bar-start">
        <router-link to="/">
          <Logo />
        </router-link>
      </div>
      <div class="top-bar-links">
        <ul class="header-link">
          <li class="link-item p-p-2">
            <router-link to="/"><h5>{{ i18n.$t("Home")}}</h5></router-link>
          </li>
          <li class="link-item text-lg p-p-2">
            <router-link to="/public/prices"><h5>{{ i18n.$t("Pricing") }}</h5></router-link>
          </li>
          <li class="link-item text-lg p-p-2">
            <router-link to="/"><h5>{{ i18n.$t("Enterprise") }}</h5></router-link>
          </li>
          <li class="link-item text-lg p-p-2">
            <router-link to="/"><h5>{{ i18n.$t("More") }}</h5></router-link>
          </li>
          <Button class="p-button-text" @click="loginModal = true"
            ><h5>{{ i18n.$t("Login") }}</h5></Button
          >
          <router-link to="/public/register">
            <Button class="p-button-danger p-button-rounded">
              <h5>{{ i18n.$t("Sign up") }}</h5>
            </Button>
          </router-link>
        </ul>
      </div>
      <div class="top-bar-end">
        <button class="p-link" @click="toggleLanguagePanel">
          <span class="pi pi-globe"></span>
          <span class="p-ml-1 p-mr-1 user-language">{{ selectedLang }}</span>
          <span class="pi pi-angle-down"></span>
        </button>
        <Button
          icon="pi pi-align-justify"
          class="p-button-rounded p-button-text mobile-more-btn"
          @click="visibleRight = true"
        />
      </div>
    </div>
    <!-- <Menu ref="langmenu" :model="languageItems" :popup="true" /> -->
    <OverlayPanel
      ref="langmenu"
      appendTo="body"
      :showCloseIcon="false"
      id="overlay_panel"
      style="width: 280px"
    >
      <div class="radio-input">
        <RadioButton
          id="fr"
          name="language"
          value="fr"
          v-model="selectedLang"
          required
          @change="setLanguage('fr')"
        />
        <label for="fr"><span class="flag flag-fr" />{{ i18n.$t("Français") }}</label>
      </div>
      <div class="radio-input p-mt-4">
        <RadioButton
          id="en"
          name="language"
          value="en"  
          v-model="selectedLang"
          required
          @change="setLanguage('en')"
        />
        <label for="en"><span class="flag flag-uk" />{{ i18n.$t("English") }}</label>
      </div>
    </OverlayPanel>
    <Dialog
      header=""
      v-model:visible="loginModal"
      :modal="true"
      class="dialog-container"
    >
      <login-form @loginModal="loginModal = $event"/>
    </Dialog>
    <Sidebar v-model:visible="visibleRight" :baseZIndex="1000" position="right">
      <ul class="sidebar-link">
        <li class="link-item p-p-2" @click="visibleRight = false">
          <router-link to="/"><h5>{{ i18n.$t("Home") }}</h5></router-link>
        </li>
        <li class="link-item text-lg p-p-2" @click="visibleRight = false">
          <router-link to="/"><h5>{{ i18n.$t("Pricing") }}</h5></router-link>
        </li>
        <li class="link-item text-lg p-p-2" @click="visibleRight = false">
          <router-link to="/"><h5>{{ i18n.$t("Enterprise") }}</h5></router-link>
        </li>
        <li class="link-item text-lg p-p-2" @click="visibleRight = false">
          <router-link to="/"><h5>{{ i18n.$t("More") }}</h5></router-link>
        </li>
        <Button class="p-button-text" @click="loginModal = true"
          ><h5 @click="visibleRight = false">{{ i18n.$t("Login") }}</h5></Button
        >
        <router-link to="/public/register">
          <Button class="p-button-danger p-button-rounded" @click="visibleRight = false">
            <h5>{{ i18n.$t("Sign up") }}</h5>
          </Button>
        </router-link>
      </ul>
    </Sidebar>
  </header>
</template>

<script>
import Logo from '../../UI/Logo.vue';
import LoginForm from '../LoginForm.vue';
import useI18n from '@/plugins/useI18n';
import { SET_LANGUAGE } from '../../../store/types';
export default {
  components: {
    Logo,
    LoginForm
  },
  data() {
    return {
      languageItems: [
        {
          label: 'Français',
          language: 'fr',
          code: 'fr',
          command: () => {
            this.setLanguage('fr');
          }
        },
        {
          label: 'English',
          code: 'uk',
          command: () => {
            this.setLanguage('en');
          }
        }
      ],
      selectedLang: 'fr',
      loginModal: false,
      visibleRight: false
    };
  },
  methods: {
    setLanguage(lang) {
      this.selectedLang = lang;
      this.$store.commit(SET_LANGUAGE, lang);
      this.i18n.locale.value = lang;
      this.toggleLanguagePanel();
    },
    toggleLanguagePanel(event) {
      this.$refs.langmenu.toggle(event);
    }
  },
  mounted() {
    this.$store.commit(SET_LANGUAGE, this.selectedLang);
    this.i18n.locale.value = this.selectedLang;
  },
  setup() {
    const { i18n } = useI18n();
    return {
      i18n
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 50vw;
}
.public-header {
  width: 100%;
  padding: 10px;
  height: 80px;
  display: flex;
  align-items: center;
  background: #d7dbe8;
}
.top-bar {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header ul {
  display: flex;
  align-items: center;
  list-style: none;
}

button:focus {
  outline: none;
}
h5 {
  margin-bottom: 0;
}
.user-language {
  text-transform: capitalize;
  font-size: 18px;
}
::v-deep(.p-dialog-header) {
  justify-content: flex-end;
}
@media (min-width: 768px) {
  .mobile-more-btn {
    display: none;
  }
}
@media (max-width: 767px) {
  h5 {
    font-size: 1rem;
  }
  .user-language {
    font-size: 1rem;
  }
  .top-bar-links {
    display: none;
  }
  .top-bar-end {
    display: flex;
  }
  .mobile-more-btn {
    margin-left: 10px;
  }
}
ul.sidebar-link {
  display: flex;
  flex-direction: column;
  list-style: none;
}
.radio-input {
  display: flex;
  align-items: center;
  label {
    display: flex;
    align-items: center;
  }
}
</style>
<style lang="scss">
.p-overlaypanel::before {
  display: none !important;
}
.p-overlaypanel::after {
  display: none !important;
}
.dialog-container {
  width: 50vw;
}
@media (max-width: 767px) {
  .dialog-container {
    width: 90vw;
  }
}
</style>
