import axios from '../plugins/axios';
import { FETCH_DOCUMENTS, SET_DOCUMENTS, GET_DOCUMENTS } from '../store/types';

const state = {
  documents: []
};

const actions = {
  async [FETCH_DOCUMENTS](context, owner_id) {
    const res = await axios.get(`document?owner_id=${owner_id}`);
    context.commit(SET_DOCUMENTS, res.data.data.items);
  }
};

const mutations = {
  [SET_DOCUMENTS](state, payload) {
    state.documents = payload;
  }
};

const getters = {
  [GET_DOCUMENTS]: (state) => state.documents
};

export default {
  state,
  actions,
  mutations,
  getters
};
