<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar
      @menu-toggle="onMenuToggle"
      @profile-menu="toggleProfileMenu"
      :class="{ 'calendar-top-bar': calendarPage && !staticMenuInactive }"
    />
    <Menu ref="menu" :model="overlayMenuItemsWithPermissions" :popup="true" />
    <transition name="layout-sidebar">
      <div
        :class="sidebarClass"
        @click="onSidebarClick"
        v-show="isSidebarVisible()"
      >
        <div v-if="!calendarPage" class="layout-logo p-d-flex p-jc-center">
          <router-link to="/">
          <span v-if="logo  === ''" class="">
            {{ agency.name }} 
          </span>
          <span v-if="logo  != ''" class="">
            <img class="logo" alt="Logo" :src="logo" />
          </span>  
          </router-link>
        </div>
        <AppProfile v-if="!calendarPage" @logout="logOut" />
        <AppMenu
          v-if="!calendarPage"
          :model="menu"
          @menuitem-click="onMenuItemClick"
        />
        <CalendarSideBar v-if="calendarPage" />
      </div>
    </transition>
    <div
      class="layout-main"
      :class="{ 'layout-calendar-main': calendarPage && !staticMenuInactive }">
      <router-view />
    </div>
    <AppFooter v-if="!removeFooter" />
  </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppProfile from './AppProfile.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';
import CalendarSideBar from '../../components/Calendar/CalendarSideBar.vue';
import { MENU_ITEMS } from './menuItems';
import {
  LOG_OUT,
  GET_AGENCY_MODEL,
  CLEAR_USERDATA
} from '../../store/types';
import { loadLogo } from '@/plugins/utility.js';

export default {
  components: {
    AppTopBar: AppTopBar,
    AppProfile: AppProfile,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
    CalendarSideBar
  },
  data() {
    return {
      layoutMode: 'static',
      layoutColorMode: 'light',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: MENU_ITEMS,
      overlayMenuItems: [
        {
          label: 'Profile',
          icon: 'pi pi-user',
          to: '/profile/myprofile',
          permission: 'v1.user.view',
        },
        {
          label: 'Agency',
          to: '/agency',
          permission: 'none',
        },
        {
          label: 'Setting',
          icon: 'pi pi-cog',
          to: '/profile/settings',
          permission: 'v1.setting.view'
        },
        {
          label: 'Configuration',
          to: '/configuration',
          permission: 'none',
        },
        {
          label: 'Category',
          to: '/category',
          permission: 'v1.category.index',
        },
        {
          label: 'New feature (soon)',
          icon: 'pi pi-clock'
        },
        {
          separator: true
        },
        {
          label: 'Logout',
          icon: 'pi pi-fw pi-power-off',
          command: () => {
            this.logOut();
          }
        }
      ]
    };
  },
  setup() {
    return { loadLogo };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    }
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
            'gi'
          ),
          ' '
        );
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay') return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
    toggleProfileMenu(event) {
      this.menuClick = true;
      this.$refs.menu.toggle(event);
    },
    logOut() {
      this.$store.dispatch(LOG_OUT);
      this.$store.commit(CLEAR_USERDATA);
      this.$router.push({ path: '/auth/connect' });
    }
  },
  computed: {
    overlayMenuItemsWithPermissions() {
      let arr = [];

      this.overlayMenuItems.forEach(el => {
        if(this.can(el.permission)) {
          arr.push(el)
        }
      })

      return arr
    },
    removeFooter() {
      return this.$route.path.includes('calendar');
    },
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive':
            this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active':
            this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$appState.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.ripple === false
        }
      ];
    },
    sidebarClass() {
      return [
        'layout-sidebar',
        {
          'layout-sidebar-dark': this.layoutColorMode === 'dark',
          'layout-sidebar-light': this.layoutColorMode === 'light',
          'layout-calendar': this.calendarPage === true
        }
      ];
    },
    agency() {
      return this.$store.getters[GET_AGENCY_MODEL];
    },
    logoUrl() {
      if (
        this.agency.logo &&
        this.agency.logo !== process.env.VUE_APP_HOST_API
      ) {
        return this.agency.logo;
      } else return null;
    },
    logo() {
      if (loadLogo(this.logoUrl)) {
        return this.logoUrl;
      } else {
        return this.layoutColorMode === 'dark'
          ? ''
          : '';
      }
    },

    calendarPage() {
      return this.$route.meta.calendar || false;
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else this.removeClass(document.body, 'body-overflow-hidden');
  }
};
</script>

<style lang="scss">
.calendar-top-bar {
  left: 320px !important;
}
</style>
<style lang="scss" scoped>
.layout-calendar {
  width: 320px;
}
.layout-main.layout-calendar-main {
  padding: 30px 2rem 2rem 2rem;
}
@media (max-width: 1024px) {
  .layout-main.layout-calendar-main {
    width: 100%;
    margin-left: 0 !important;
  }
  .layout-wrapper .layout-topbar {
    left: 0 !important;
  }
  .layout-wrapper .layout-sidebar {
    left: -330px;
  }
}
@media (min-width: 1025px) {
  .layout-wrapper.layout-static .layout-main {
    margin-left: 250px;
    &.layout-calendar-main {
      margin-left: 320px;
    }
  }
}
.logo {
  height: 50px;
}
</style>