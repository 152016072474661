import _ from 'lodash';
import axios from '../plugins/axios';
import AgencyModel from '../models/agency';

import {
  FETCH_AGENCY,
  UPDATE_AGENCY,
  SET_AGENCY_MODEL,
  GET_AGENCY_MODEL,
  SET_ALL_CATEGORIES,
  GET_SELECTED_CATEGORIES,
  FETCH_ALL_SERVICES,
  SET_SERVICES,
  SET_SERVICE,
  CREATE_SERVICE,
  GET_SERVICES,
  GET_SERVICE_MODEL,
  GET_CATEGORY_TREE,
  GET_SELECTED_CARTEGORY_TREE_VALUE,
  EDIT_SERVICE,
  GET_USERDATA,
  FETCH_BRANDS,
  SET_BRANDS,
  GET_BRANDS
} from './types';

const state = {
  agency: {},
  agencyModel: new AgencyModel({}),
  services: [],
  service: [],
  brands: [],
  categories: [],
  categoryTree: { root: [] },
  selectedCategories: [],
  selectedCategoryTreeValue: [],
};

const actions = {
  async [FETCH_AGENCY]({ commit, getters }) {
    const orgId = getters[GET_USERDATA].organisation_id;
    try {
      const res = await axios.get(`organisation/${orgId}`);
      const resData = res.data.data;
      if (resData.brand_id) {
        resData.brand_id = JSON.parse(resData.brand_id)
      }
      commit(SET_AGENCY_MODEL, resData);
    } catch (err) {
      console.log('[agency error]', err);
    }
  },
  async [FETCH_BRANDS]({ commit }) {
    try {
      const res = await axios.get('brand');
      commit(SET_BRANDS, res.data.data.items)
    } catch (err) {
      console.log('[agency error]', err);
    }
  },
  async [UPDATE_AGENCY]({ commit, getters }, payload) {
    const orgId = getters[GET_USERDATA].organisation_id;
    let formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    try {
      await axios.put(`organisation/${orgId}`, formData);
      commit(SET_AGENCY_MODEL, payload);
    } catch (err) {
      console.log('[agency update error]', err);
    }
  },

  async [FETCH_ALL_SERVICES]({ getters, commit }) {
    try {
      const orgId = getters[GET_USERDATA].organisation_id;
      const res = await axios.get(`service?organisation_id=${orgId}`);
      commit(SET_SERVICES, res.data.data.items);
    } catch (err) {
      console.log('[service error]', err.message);
      return Promise.reject();
    }
  },


  async [CREATE_SERVICE](context, payload) {
    try {
      await axios.post(`service`, payload)
    } catch (err) {
      console.log('[service error]', err);
    }
  },

  async [EDIT_SERVICE](context, e) {
    try {
      await axios.put(`service/${e.id}`, e.payload);
    } catch (err) {
      console.log('[service error]', err);
    }
  },
};

const mutations = {
  [SET_AGENCY_MODEL](state, payload) {
    payload.logo = `${process.env.VUE_APP_HOST_MANAGER}${process.env.VUE_APP_IMAGE_PATH}` + payload.logo;
    state.agency = payload;
    state.agencyModel = new AgencyModel(payload);
    state.selectedCategories = payload.category;
    let selectedCategoryTreeValue = {};
    if (payload.category) {
      for (const cat of payload.category) {
        selectedCategoryTreeValue = {
          ...selectedCategoryTreeValue,
          [cat.id]: {
            checked: true,
            partialChecked: false
          }
        };
        const categoryItem = _.find(state.selectedCategories, function (o) {
          return o.id === cat.id;
        });

        const ancestors = getAllAncestors(
          categoryItem,
          state.categories,
          payload.category
        );
        selectedCategoryTreeValue = {
          ...selectedCategoryTreeValue,
          ...ancestors
        };
      }
      state.selectedCategoryTreeValue = selectedCategoryTreeValue;
    }
  },
  [SET_BRANDS](state, value) {
    state.brands = value;
  },
  [SET_SERVICES](state, payload) {
    var services = [];
    if (payload) {
      for (let j = 0; j < payload.length; j++) {
        services.push(payload[j]);
      }
    }
    state.services = services;
  },
  [SET_SERVICE](state, payload) {
    state.service = payload;
  },

  [SET_ALL_CATEGORIES](state, payload) {

    const categoryTree = [];
    state.categories = [...payload];

    for (const category of payload) {
      if (category.parent_id === '') {
        const node = {
          ...category,
          active: false,
          key: category.id,
          label: category.name,
          data: category.type,
          selectable: false,
        };
        node.children = getChildren(node, payload);
        categoryTree.push(node);
      }
    }
    state.categoryTree = categoryTree;
  }
};

const getters = {
  [GET_AGENCY_MODEL]: (state) => state.agency,
  [GET_SERVICE_MODEL]: (state) => state.service,
  [GET_SERVICES]: (state) => state.services,
  [GET_SELECTED_CATEGORIES]: (state) =>
    state.selectedCategories ? state.selectedCategories : [],
  [GET_CATEGORY_TREE]: (state) => state.categoryTree,
  [GET_SELECTED_CARTEGORY_TREE_VALUE]: (state) =>
    state.selectedCategoryTreeValue,
  [GET_BRANDS]: (state) => state.brands

};

function getChildren(parent, list) {
  const res = [];
  const children = _.filter(list, function (o) {
    return o.parent_id === parent.id;
  });
  for (const child of children) {
    const node = {
      ...child,
      key: child.id,
      label: child.name,
      data: child.key,
    };
    if (child.childs.length > 0) {
      node.selectable = false;
    }
    node.children = getChildren(node, list);
    res.push(node);
  }
  return res;
}

function getAllAncestors(node, list, checkedNodes) {
  let res = {};
  if (node._id === '') {
    return res;
  }
  const parent = _.find(list, function (o) {
    return o.id === node._id;
  });

  if (parent) {
    const partialChecked = _.findIndex(checkedNodes, function (o) {
      return o.id === parent.id;
    });
    if (partialChecked === -1) {
      res = {
        [parent.id]: {
          checked: false,
          partialChecked: true
        }
      };
    }

    const ancestor = getAllAncestors(parent, list);
    res = { ...res, ...ancestor };
  }
  return res;
}

export default {
  state,
  actions,
  mutations,
  getters
};
