<template>
  <div class="login-modal">
    <h3 class="font-bold text-center p-mt-4">
      {{ i18n.$t('Nipa Connection') }}
    </h3>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col p-px-0">
        <form
          class="profile-form rounded"
          :class="active ? 'login-form' : 'profile-form rounded'"
          @submit="onConnect"
        >
          <div class="form-input">
            <p class="font-semibold text p-mt-2">{{ i18n.$t('Username') }}</p>
            <InputText
              name="username"
              type="text"
              v-model="username"
              :placeholder="i18n.$t('Username')"
              :class="{ 'p-error': !!errors.username }"
            />
          </div>
          <div class="form-input">
            <p class="font-semibold text p-mt-2">{{ i18n.$t('Password') }}</p>
            <InputText
              name="password"
              type="password"
              class="border border-gray-500 focus:border-main"
              v-model="password"
              :placeholder="i18n.$t('Password')"
              :class="{ 'p-error': !!errors.password }"
            />
          </div>
          <div v-if="showResult" class="mt-2">
            <p v-if="isError" class="font-semibold text-red-600">
              {{ i18n.$t('Oops, something went wrong') }}.
            </p>
            <p v-else class="font-semibold text-green-600">
              {{ i18n.$t('Successfully log in') }}
            </p>
          </div>
          <div class="form-input">
            <Button type="submit" class="loading-btn">
              <spinner v-if="isLoading" color="white" />
              <span v-else>{{ i18n.$t('Connect') }}</span>
            </Button>
            <div @click="onCloseModel">
              <router-link to="/public/changepassword" class="router-link">
                {{ i18n.$t('password lost') }} ?
              </router-link>
            </div>
          </div>
        </form>
      </div>
      <div class="p-field p-col-sm-12 p-col">
        <google-login /> <face-book-login />
        <!-- <apple-login />  -->
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <div class="form-input">
          <p class="text-center text-sm mt-1">
            {{ i18n.$t('Do not have an account yet') }}?
          </p>
          <Button
            type="button"
            class="loading-btn p-button-outlined p-button-help"
            @click="onRegisterModel"
          >
            {{ i18n.$t('Register') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { LOG_IN } from '../../store/types';
import Spinner from '../../components/UI/Spinner.vue';
import GoogleLogin from '../../components/SocialLogin/GoogleLogin.vue';
import FaceBookLogin from '../../components/SocialLogin/FaceBookLogin.vue';
// import AppleLogin from "../../components/SocialLogin/AppleLogin.vue";
import useI18n from '@/plugins/useI18n';
import {
  FETCH_EMPLOYEES,
  GET_EMPLOYEES,
  GET_USERDATA,
  FETCH_ALL_SERVICES,
  GET_SERVICES
} from '@/store/types';

export default {
  components: {
    Spinner,
    FaceBookLogin,
    GoogleLogin
    // AppleLogin,
  },
  data() {
    return {
      loginModel: false
    };
  },
  methods: {
    onCloseModel() {
      this.$emit('loginModal', this.loginModel);
    },
    onRegisterModel() {
      this.$router.push({ path: '/public/register' });
      this.onCloseModel();
    }
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { i18n } = useI18n();
    // const username = ref();
    // const password = ref();
    const showResult = ref(false);
    const isError = ref(false);
    const isLoading = ref(false);
    const active = ref(false);
    const { handleSubmit, errors } = useForm({
      validationSchema: yup.object().shape({
        username: yup.string().required(),
        password: yup.string().required()
      })
    });

    const { value: username } = useField('username', yup.string().required());
    const { value: password } = useField('password', yup.string().required());

    const onConnect = handleSubmit(async () => {
      isLoading.value = true;
      showResult.value = false;
      try {
        await store.dispatch(LOG_IN, {
          username: username.value,
          password: password.value
        });

        const userData = computed(() => store.getters[GET_USERDATA]);
        const employees = computed(() => {
          //console.log(store.getters[GET_EMPLOYEES].length);
          return store.getters[GET_EMPLOYEES];
        });
        const services = computed(() => store.getters[GET_SERVICES]);
        await store.dispatch(FETCH_EMPLOYEES, userData.value.organisation_id);
        await store.dispatch(FETCH_ALL_SERVICES);

        isError.value = false;

        if (employees.value.length > 1 && services.value.length > 0) {
          router.push({ path: '/' });
        } else {
          router.push({ path: '/get-started' });
        }
        // const items = { ...localStorage };

        // const initialLoggedIn = localStorage.getItem();

        // console.log("items ? 1 ", items);
      } catch (e) {
        console.log(e);
        isError.value = true;
      }
      showResult.value = true;
      isLoading.value = false;
    });

    function onRegister() {
      // router.push({ path: `/profile/register` });
    }

    function onPassword() {
      router.push({ path: `/public/changepassword` });
    }

    function socialLogin() {
      active.value = true;
    }

    return {
      username,
      password,
      errors,
      showResult,
      isError,
      isLoading,
      onConnect,
      onRegister,
      onPassword,
      socialLogin,
      active,
      i18n
    };
  }
};
</script>

<style lang="scss" scoped>
.a {
  color: #00dfdf;
}
.login-modal {
  position: relative;
}
.profile-form {
  width: 350px;
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lazy-image {
  width: 280px;
  height: 60px;
  background: #cccccc;
}
.form-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 20px;
  input {
    border-radius: 5px;
    padding: 5px 10px;
    &:focus {
      outline: none;
    }
  }
  &:first-child {
    margin-top: 0;
  }
  button:focus {
    outline: none;
  }
}
.loading-btn {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}
.login-form {
  display: none;
}
.form-input span {
  text-align: center;
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: 600;
}
/* .loading-btn .p-button {
  padding: 0 !important;
} */
@media (max-width: 557px) {
  .profile-form {
    margin: 0 auto;
    width: 310px;
    max-width: 100%;
  }
}
@media (min-width: 1005px) and (max-width: 1200px) {
  .profile-form {
    margin: 0 auto;
    width: 420px;
    max-width: 100%;
  }
}
</style>