import {
  createApp
} from 'vue';
import {
  reactive
} from 'vue';
import mitt from 'mitt';
import router from './router';
import store from './store';
import {
  permissions
} from '@/plugins/permissions'
import App from './App.vue';

/***** Layouts ****/
import AppLayout from './layout/app/Layout.vue';
import PublicLayout from './layout/public/PublicLayout.vue';
import InputPhone from './components/UI/InputPhone.vue';

/**** Components ****/
import Password from 'primevue/password';
import AutoComplete from 'primevue/autocomplete';
import ConfirmationService from 'primevue/confirmationservice';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import FileUpload from 'primevue/fileupload';
import Menu from 'primevue/menu';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import Sidebar from 'primevue/sidebar';
import Steps from 'primevue/steps';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import Avatar from 'primevue/avatar';
import BlockUI from 'primevue/blockui';
import Editor from 'primevue/editor';
import ButtonConfirm from '@/components/UI/ButtonConfirm';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Divider from 'primevue/divider';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
/** CKEditor5 */
import CKEditor from '@ckeditor/ckeditor5-vue';
import PrimeVue from 'primevue/config';
/** CSS */
import './assets/layout/layout.scss';
import './assets/layout/flags/flags.css';
import './assets/scss/styles.scss';

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0);
  next();
});


const app = createApp(App);

app.config.globalProperties.$appState = reactive({
  inputStyle: 'outlined'
});

app.use(ToastService);
app.use(router);
app.use(store);
app.use(CKEditor);
app.use(PrimeVue);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.component('app-layout', AppLayout);
app.component('public-layout', PublicLayout);
app.component('input-phone', InputPhone);
app.component('AutoComplete', AutoComplete);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Password', Password);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('ButtonConfirm', ButtonConfirm);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Menu', Menu);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OverlayPanel', OverlayPanel);
app.component('ProgressSpinner', ProgressSpinner);
app.component('RadioButton', RadioButton);
app.component('Sidebar', Sidebar);
app.component('Steps', Steps);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Textarea', Textarea);
app.component('Avatar', Avatar);
app.component('Toast', Toast);
app.component('Tree', Tree);
app.component('FileUpload', FileUpload);
app.component('BlockUI', BlockUI);
app.component('Editor', Editor);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Divider', Divider);

app.use(ConfirmationService);
app.mixin(permissions)

export const emitter = mitt();

app.config.globalProperties.emitter = emitter;
app.mount('#app');