<template>
  <div class="layout-profile">
    <div class="user">
      <router-link :to="{ name: can('v1.user.update') ? 'profile-profile' : null }">
        <AvatarLogo :data="userData"/>
      </router-link>
      <button class="p-link layout-profile-link p-mt-2" @click="onClick">
        <span v-if="userData !== null" class="username">
          {{ userData && userData.first_name }}&nbsp;
          {{ userData && userData.last_name }}
        </span>
        <span v-if="userData.first_name  === ''" class="username">
          {{ userData.organisation_name }}
        </span>
        <i class="pi pi-fw pi-cog"></i>
      </button>
    </div>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li v-if="can('v1.user.view')">
          <router-link to="/profile/myprofile">
            <button class="p-link">
              <i class="pi pi-fw pi-user"></i
              ><span>{{ i18n.$t('Profile') }} ({{ userData.role }})</span>
            </button>
          </router-link>
        </li>
      <li>
          <router-link to="/agency">
            <button class="p-link">
              <i class="pi pi-cog"></i><span>{{ i18n.$t('Agency') }}</span>
            </button>
          </router-link>
        </li>
        <li v-if="can('v1.setting.view')">
          <router-link to="/profile/settings">
            <button class="p-link">
              <i class="pi pi-cog"></i><span>{{ i18n.$t('Settings') }}</span>
            </button>
          </router-link>
        </li>
        <li>
          <router-link to="/configuration">
            <button class="p-link">
              <i class="pi pi-cog"></i><span>{{ i18n.$t('Configuration') }}</span>
            </button>
          </router-link>
        </li>
        <li>
          <button class="p-link" @click="$emit('logout')">
            <i class="pi pi-fw pi-power-off"></i
            ><span>{{ i18n.$t('Logout') }}</span>
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>
<script>
import { computed } from 'vue';
import useI18n from '@/plugins/useI18n';
import AvatarLogo from '../../components/UI/AvatarLogo.vue';
import {
  GET_USERDATA
} from '@/store/types';
import {useStore} from 'vuex';

export default {
  components: { AvatarLogo },
  data() {
    return {
      expanded: false
    };
  },
  methods: {
    onClick(event) {
      this.expanded = !this.expanded;
      event.preventDefault();
    }
  },
  setup() {
    const store = useStore();
    const userData = computed(() => store.getters[GET_USERDATA]);

    const { i18n } = useI18n();

    return { i18n, userData };
  }
};
</script>

<style scoped>
.user {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.username {
  text-transform: capitalize;
}
</style>