import RDVModel from '../models/rdv';
import { SET_RDV_MODEL, GET_RDV_MODEL } from '../store/types';

const state = {
  rdv: new RDVModel({}).toJson(),
  rdvModel: new RDVModel({})
};

const mutations = {
  [SET_RDV_MODEL](state, payload) {
    state.rdv = payload;
    state.rdvModel = new RDVModel(payload);
  }
};

const getters = {
  [GET_RDV_MODEL]: (state) => state.rdv
};

export default {
  state,
  mutations,
  getters
};
