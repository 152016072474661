<template>
    <div class="">
        <Button class="my-3 login-facebook" @click="facebook">
           <i class="pi pi-facebook" style="fontSize: 1rem"></i>  <span>{{ i18n.$t("SignIn with Facebook") }}</span>
        </Button>
    </div>
</template>

<script>
import { firebase } from "@/plugins/firebase";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import useI18n from '@/plugins/useI18n';
import {
  SOCIAL_LOG_IN
} from '../../store/types';

export default {
  data() {
    return {
      loginstate: false,
      sheet: false,
      router: useRouter(),
      store: useStore()
    };
  },
  methods: {
    facebook() {
      var provider = new firebase.auth.FacebookAuthProvider();
      console.log(provider, 'provider');
      this.auth(provider);
    },
    async auth(provider) {
      try {
        const result = await firebase.auth().signInWithPopup(provider);
        const user = result.user;
        var token = result.credential.accessToken;
        const myuser = {
          photo: user.photoURL,
          token: token,
          address: "",
          currency_id: "",
          email: user.email,
          first_name: user.displayName,
          id: user.uid,
          language_id: "en",
          last_name: "",
          login: true,
          organisation_id: user.uid,
          organisation_name: user.displayName,
          phone: user.phoneNumber,
          role: "",
          status: 0,
          username: ""
        }
        console.log(myuser, 'myuser');
        this.store.dispatch(SOCIAL_LOG_IN, myuser);
        this.router.push({ path: "/" });
      } catch (error) {
        console.log(error, 'err');
      }
    },
  },
  setup() {
    const { i18n } = useI18n();
    return {
      i18n
    }
  }
};
</script>
<style scoped>
.login-facebook {
  width: 100%;
  margin: 10px 0;
  border-radius: 0;
  background-color: white;
  border-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0px 0px 4px 1px #ccc;
  padding: 10px;
  justify-content: center;
}
.login-facebook span {
  color: #2196F3;
  margin-left: 5px;
}
.login-facebook:hover span {
  color: #fff;
}
</style>