import {
  SET_UI_NOTIFICATION,
  GET_UI_NOTIFICATION_CONTENT,
  GET_UI_NOTIFICATION_STATE,
  GET_UI_LOADING,
  SET_UI_LOADING
} from './types';
const state = {
  showNotification: false,
  notificationType: 'Success',
  notificationText: '',
  loading: false,
};

const actions = {};

const mutations = {
  [SET_UI_NOTIFICATION](state, payload) {
    state.showNotification = payload.showNotification;
    state.notificationType = payload.notificationType;
    state.notificationText = payload.notificationText;
  },
  [SET_UI_LOADING](state, value) {
    state.loading = value;
  }
};

const getters = {
  [GET_UI_NOTIFICATION_STATE]: (state) => state.showNotification,
  [GET_UI_NOTIFICATION_CONTENT]: (state) => ({
    type: state.notificationType,
    text: state.notificationText
  }),
  [GET_UI_LOADING]: (state) => state.loading,
};

export default {
  state,
  actions,
  mutations,
  getters
};
