import axios from '../plugins/axios';
import _ from 'lodash';

import {
  FETCH_CATEGORY_SERVICE,
  SET_CATEGORY_SERVICE,
  GET_CATEGORY_SERVICE,
  FETCH_CATEGORY_PARENTS,
  GET_CATEGORY_PARENTS,
  SET_CATEGORY_PARENTS,
  GET_ALL_CATEGORIES,
  FETCH_ALL_CATEGORIES,
  SET_ALL_CATEGORIES,
  FETCH_TREE_CHILD_CATEGORIES,
  SET_TREE_CHILD_CATEGORIES,
  GET_TREE_CHILD_CATEGORIES
} from './types';

const state = {
  category: null,
  category_parents: null,
  categories: null,
  childrenCategories: null
};


const actions = {
  async [FETCH_CATEGORY_SERVICE](context, organisation_id) {
    const res = await axios.get(`category?type=service&organisation_id=${organisation_id}`);
    context.commit(SET_CATEGORY_SERVICE, res.data.data.items);
  },
  async [FETCH_ALL_CATEGORIES](context) {
    try {
      const res = await axios.get(`category`);
      context.commit(SET_ALL_CATEGORIES, res.data.data.items);
      context.dispatch(FETCH_CATEGORY_PARENTS, res.data.data.items);
      //console.log('all categories fetched');
    } catch (err) {
      console.log('[category error]', err);
    }
  },
  async [FETCH_TREE_CHILD_CATEGORIES](context, categories) {
    try {
      let children = [];
      for (const item of categories) {
        children.push(item.id);
      }
      const res = await axios.get('/category/fulltree?ids=' + children.join(','));

      let treeCats = makeTreeSelect(res.data.data.items);
      for (const i in treeCats) {
        if (treeCats[i]['children'].length == 0) {
          delete treeCats[i];
        }
      }
      treeCats = Object.values(treeCats);
      context.commit(SET_TREE_CHILD_CATEGORIES, treeCats);
      //console.log('all children categories fetched', treeCats);
    } catch (err) {
      console.log('[category error]', err);
    }
  },
  async [FETCH_CATEGORY_PARENTS](context) {
    try {
      const res = await axios.get('/category/parents')
      context.dispatch(SET_CATEGORY_PARENTS, res.data.data.items);
      //console.log('parent categories fetched');
    } catch (err) {
      console.log('[error]', err)
    }
  },
  [SET_CATEGORY_PARENTS](context, payload) {
    context.state.category_parents = [];
    const list = context.getters[GET_ALL_CATEGORIES];

    for (const category of payload) {
      if (category.parent_id === '') {
        const node = {
          ...category,
          key: category.id,
          label: category.name,
          data: category.type,
        };
        node.children = getChildren(node, list);
        context.state.category_parents.push(node);
      }
    }
  },
};

const mutations = {
  [SET_CATEGORY_SERVICE](state, payload) {
    state.category = payload;
  },
  [SET_ALL_CATEGORIES](state, payload) {
    state.categories = [...payload];
  },
  [SET_TREE_CHILD_CATEGORIES](state, payload) {
    state.childrenCategories = [...payload];
  }
};

const getters = {
  [GET_CATEGORY_SERVICE]: (state) => state.category,
  [GET_CATEGORY_PARENTS]: (state) => state.category_parents,
  [GET_ALL_CATEGORIES]: (state) => state.categories,
  [GET_TREE_CHILD_CATEGORIES]: (state) => state.childrenCategories,
};

function getChildren(parent, list) {
  const res = [];

  const children = _.filter(list, function (o) {
    return o.parent_id === parent.id;
  });
  for (const child of children) {
    const node = {
      ...child,
      key: child.id,
      label: child.name,
      data: child.key,
    };
    if (child.childs.length > 0) {
      node.selectable = false;
    }
    node.children = getChildren(node, list);
    res.push(node);
  }
  return res;
}

function makeTreeSelect(list) {
  const res = [];

  for (const child of list) {
    const node = {
      ...child,
      key: child.id,
      label: child.name,
    };
    if (child.childs.length > 0) {
      node.selectable = false;
    } else {
      node.selectable = true;
    }
    node.children = makeTreeSelect(node.childs);
    res.push(node);
  }
  return res;
}

export default {
  state,
  actions,
  mutations,
  getters
};