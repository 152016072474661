<template>
  <div class="layout-footer">
    <span class="footer-text" style="margin-right: 5px">Nipa @2021</span>
  </div>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style scoped></style>
