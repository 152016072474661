<template>
  <div class="register" v-if="!showResult">
    <h3 class="font-bold text-center">
      {{ i18n.$t('Apin Professional Registration') }}
    </h3>
    <div class="register-container">
      <form class="register-form" ref="register">
        <div class="form-input">
          <p class="font-semibold">{{ i18n.$t('Company Name') }}</p>
          <InputGroup :invalid="!!errors.organisation" :validation="valid">
            <InputText
              type="text"
              v-model="organisation"
              name="organisation"
              :class="{ 'p-invalid': !!errors.organisation && valid }"
              @focus="updateShowUpload"
            />
          </InputGroup>
        </div>
        <div class="form-input">
          <p class="font-semibold p-mt-2">{{ i18n.$t('Address') }}</p>
          <InputGroup
            :invalid="address.fullAddress.length == 0"
            :validation="valid"
            @click="addressFields()"
          >
            <InputText
              type="text"
              placeholder="Fill your company address"
              v-model="address.fullAddress"
              name="address"
              :class="{ 'p-invalid': address.fullAddress.length < 5 && valid }"
            />

            <div class="overlay"></div>
            <i class="pi pi-arrow-right" v-if="!valid"></i>
          </InputGroup>
        </div>
        <div class="form-input">
          <p class="font-semibold p-mt-2">{{ i18n.$t('Email') }}</p>
          <InputGroup :invalid="!!errors.email" :validation="valid">
            <InputText
              type="email"
              v-model="email"
              name="email"
              class="border border-gray-500 focus:border-main rounded p-1"
              :class="{ 'p-invalid': !!errors.email && valid }"
              @focus="changeImage(3)"
            />
          </InputGroup>
        </div>
        <div class="form-input">
          <p class="font-semibold text p-mt-2">{{ i18n.$t('Telephone') }}</p>
          <InputGroup
            :invalid="!phoneNumber & valid"
            :validation="valid"
            class="phone-select"
            style="z-index: 999"
            @click="changeImage(4)"
          >
            <input-phone
              @phone="phone = $event"
              @phoneNumber="phoneNumber = $event"
              type="number"
              :placeholder="i18n.$t('Phone Number')"
              name="phone"
              :class="{
                'p-invalid': !phoneNumber && phoneNumber !== '' && valid
              }"
            />
          </InputGroup>
        </div>
        <div>
          <input type="hidden" name="username" v-model="username" />
          <input type="hidden" name="language_id" v-model="language_id" />
          <input type="hidden" name="currency_id" v-model="currency_id" />
          <input type="hidden" name="status" v-model="status" />
          <input type="hidden" name="role" v-model="role" />
        </div>
        <div class="form-input choose-cat">
          <p class="font-semibold p-mt-2">
            {{ i18n.$t('Choose professional categories') }}
          </p>
          <InputGroup
            :invalid="selectedItemsName.length == 0"
            :validation="valid"
            @click="changeImage(5)"
          >
            <InputText type="text" v-model="selectedItemsName"> </InputText>
            <div class="overlay"></div>
            <i class="pi pi-arrow-right" v-if="!valid"></i>
          </InputGroup>
        </div>

        <div class="form-input terms-conditions p-mt-4">
          <Checkbox
            v-model="agreeTerms"
            :binary="true"
            class="p-mr-2"
            :class="{ 'p-invalid': !agreeTerms && valid }"
          />
          <label class="p-d-flex p-ai-center">
            {{ i18n.$t('I agree with') }}
            <Button
              type="button"
              class="p-button-text p-ml-2"
              @click="displayTermsModal = true"
              style="padding: 0"
            >
              <span class="text-main">{{
                i18n.$t('terms and conditions')
              }}</span>
            </Button>
          </label>
        </div>
        <div class="form-input p-mt-4 btn-submit">
          <Button
            type="button"
            class="p-button-danger p-button-rounded loading-btn"
            @click="onSave(), startValid()"
          >
            <spinner v-if="isLoading" color="white" />
            <span v-if="!isLoading">{{ i18n.$t('Create Account') }}</span>
          </Button>
        </div>
      </form>
      <div
        :class="active ? 'register-image-address' : 'register-image'"
        v-if="!showUpload"
      >
        <Card style="width: 30em">
          <template #header>
            <img
              alt="user header"
              src="/assets/layout/images/usercard.png"
              v-if="rightImg.key !== 'category'"
            />
          </template>
          <template #title>
            {{ rightImg.name }}
          </template>
          <template #content>
            <p>
              <span v-if="rightImg.key == 'category'">
                <TreeSelect
                  :treeValue="categoryTree"
                  name="categories"
                  :label="i18n.$t('Select categories')"
                  @selectedItems="selectedItems = $event"
                  @click="changeImage(5)"
                />
              </span>
              <span v-if="rightImg.key == 'entreprise'">
                Fill your entreprise name
              </span>

              <span v-if="rightImg.key == 'email'"> Fill your email </span>

              <span v-if="rightImg.key == 'telephone'">
                Fill your telephone
              </span>
            </p>
          </template>
        </Card>
      </div>
      <SelectAddress
        :address="address"
        :country="country"
        v-if="active"
        @confirm="confirm"
      />

      <Card v-show="showUpload" style="width: 30em" class="upload-card">
        <template #header>
          <img
            class="img-upload"
            alt="user header"
            :src="logo ? logo.objectURL : '/assets/layout/images/usercard.png'"
          />
        </template>
        <template #title>
          <p class="font-semibold">{{ i18n.$t('Company `s logo') }}</p>
        </template>
        <template #content>
          <LogoUpload @uploadLogo="logo = $event" @clear="logo = null" />
        </template>
      </Card>

      <Dialog
        header="Terms and Conditions"
        v-model:visible="displayTermsModal"
        :style="{ width: '50vw' }"
        position="top"
        :modal="true"
      >
        <p class="text-left">
          {{
            i18n.$t(
              'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis eaqu vel assumenda facilis cupiditate provident est neque nobis voluptate error accusantium doloremque, dignissimos ducimus nam officia sed ex? A, possimus'
            )
          }}
        </p>
        <p class="text-left">
          {{
            i18n.$t(
              'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis eaqu vel assumenda facilis cupiditate provident est neque nobis voluptate error accusantium doloremque, dignissimos ducimus nam officia sed ex? A, possimus'
            )
          }}
        </p>
      </Dialog>
    </div>
  </div>

  <div v-if="showResult" class="mt-2">
    <Dialog
      header=""
      v-model:visible="loginModal"
      :modal="true"
      class="dialog-container"
    >
      <login-form @loginModal="loginModal = $event" />
    </Dialog>

    <Card v-if="!isError">
      <template #title> Registration confirmed </template>
      <template #content>
        {{ i18n.$t('Welcome registration') }} <br />
        Votre identifiant est <b>{{ username }}</b
        ><br />

        &nbsp; {{ i18n.$t('Please check your email') }}
      </template>
      <template #footer>
        <Button class="p-button-text" @click="loginModal = true"
          ><h5>{{ i18n.$t('Login') }}</h5></Button
        >
      </template>
    </Card>
    <Card v-if="isError">
      <template #title> Erreur in Registration </template>
      <template #content>
        <p class="font-semibold text-red-600">
          {{ i18n.$t('Oops, something went wrong') }}.
        </p>
      </template>
    </Card>
  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import axios from '@/plugins/axios';
import Spinner from '../../components/UI/Spinner.vue';
import TreeSelect from '../../components/UI/TreeSelect.vue';
import {
  FETCH_ALL_CATEGORIES,
  GET_CATEGORY_TREE,
  GET_ALL_CATEGORIES,
  FETCH_CATEGORY_PARENTS
} from '../../store/types';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import useI18n from '@/plugins/useI18n';
import LoginForm from '../../components/Public/LoginForm.vue';
import InputGroup from '../../components/UI/InputGroup.vue';
import SelectAddress from '../../components/Register/SelectAddress.vue';
import LogoUpload from '@/components/Register/LogoUpload.vue';
export default {
  components: {
    Spinner,
    TreeSelect,
    LoginForm,
    InputGroup,
    SelectAddress,
    LogoUpload
  },
  data() {
    return {
      loginModal: false
    };
  },
  setup() {
    const store = useStore();
    const categoryTree = computed(() => store.getters[GET_CATEGORY_TREE]);
    const register = ref();
    const showResult = ref(false);
    const country = ref();
    const isError = ref(false);
    const isLoading = ref(false);
    const agreeTerms = ref(false);
    const active = ref(false);
    const showUpload = ref(false);
    const displayTermsModal = ref(false);
    const { i18n } = useI18n();
    //console.log(categoryTree);
    const rightImages = [
      { name: 'Entreprise', key: 'entreprise' },
      { name: 'Default', key: 'default' },
      { name: 'Email', key: 'email' },
      { name: 'Telephone', key: 'telephone' },
      { name: 'Category', key: 'category' }
    ];
    const rightImg = ref(rightImages[0]);

    const locality = reactive({
      attribution: null
    });

    store.dispatch(FETCH_ALL_CATEGORIES);
    //store.dispatch(FETCH_CATEGORY_PARENTS);
    const { handleSubmit, errors } = useForm({
      validationSchema: yup.object().shape({
        organisation: yup.string().required(),
        email: yup.string().required().email(),
        postalCode: yup.string().required(),
        city: yup.string().required(),
        country: yup.string().required()
      })
    });

    const { value: organisation } = useField('organisation');
    const { value: local } = '';
    const { value: phone } = useField('phone', yup.string());
    const { value: email } = useField('email', yup.string().email().required());

    const username = new Date().getTime().toString();
    const language_id = 1;
    const currency_id = 'fr';
    const status = 10;
    const role = 'director';
    let categories = computed(() => store.getters[GET_ALL_CATEGORIES]);
    const valid = ref(false);
    const selectedItems = ref([]);

    function startValid() {
      valid.value = true;
    }
    const selectedItemsName = computed(() => {
      let value = [];
      if (categories.value) {
        let items = categories.value.filter((item) =>
          selectedItems.value.includes(item.id)
        );
        items.forEach((el) => {
          value.push(`${el.name}`);
        });
      }
      return value.join(', ');
    });

    const address = reactive({
      addressReq: '',
      fullAddress: ''
    });

    function addressFields() {
      active.value = true;
      showUpload.value = false;
    }
    function updateShowUpload() {
      showUpload.value = true;
      active.value = false;
    }

    function changeImage(idx) {
      rightImg.value = rightImages[idx - 1];
      active.value = false;
      showUpload.value = false;
    }

    function confirm(value) {
      active.value = false;
      changeImage(3);
    }

    const logo = ref(null);

    const onSave = handleSubmit(async (e) => {
      if (agreeTerms.value && phone.value.length > 3) {
        isLoading.value = true;
        showResult.value = false;
        const formData = new FormData(register.value);

        formData.append('city', address.addressReq.city);
        formData.append('postcode', address.addressReq.postcode);
        formData.append('name', address.addressReq.name);
        formData.append('logo', logo.value);
        formData.append('phone', phone.value);

        if (address.addressReq.housenumber) {
          formData.append('housenumber', address.addressReq.housenumber);
        } else {
          formData.append('housenumber', null);
        }
        if (country.value) {
          formData.append('country', country.value);
        } else {
          formData.append('country', '');
        }

        if (selectedItems.value) {
          formData.append(
            'categories',
            JSON.stringify([...selectedItems.value])
          );
        }

        try {
          await axios.post('user', formData);
          isError.value = false;
        } catch {
          isError.value = true;
        }

        showResult.value = true;
        isLoading.value = false;
      }
    });

    return {
      local,
      logo,
      locality,
      categoryTree,
      register,
      rightImg,
      organisation,
      email,
      phone,
      username,
      language_id,
      currency_id,
      status,
      role,
      errors,
      agreeTerms,
      displayTermsModal,
      showResult,
      isError,
      isLoading,
      changeImage,
      onSave,
      addressFields,
      active,
      showUpload,
      i18n,
      country,
      address,
      valid,
      startValid,
      selectedItems,
      selectedItemsName,
      updateShowUpload,
      confirm
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep(.tree-parents) {
  margin-top: 0 !important;
}
.register {
  position: relative;
  min-height: 700px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}
.p-invalid {
  outline: 1px solid #f44336;
  border: none;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  &.p-checkbox {
    outline: none;
    .p-checkbox-box {
      border: 2px solid #f44336;
    }
  }
}
.choose-cat {
  margin-top: 10px;
  cursor: pointer;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.register-container {
  // min-height:600px;
  display: flex;
  flex-direction: row;
  // align-items: center;
  margin-top: 50px;
}
.register-image {
  width: 50%;
  box-sizing: border-box;
  padding-left: 30px;
  img {
    width: 100%;
    border-radius: 15px;
  }
  .p-card {
    overflow-y: auto;
  }
}
.register-form {
  width: 50%;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
input:focus {
  outline: none;
}
.form-input {
  width: 100%;
  max-width: 380px;
}
.form-input input {
  width: 100%;
}
.loading-btn {
  height: 40px;
  border-radius: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: -1;
}
.router-link {
  text-decoration: underline;
  color: #28476f;
}
.terms-conditions {
  display: flex;
  align-items: center;
}
.terms-conditions input {
  width: unset !important;
  margin-right: 8px;
}
p {
  margin-bottom: 0;
}
.address-fields {
  display: block;
  width: 40%;
}
.other-fields {
  display: none;
}
.register-image-address {
  display: none;
}
.btn-submit {
  z-index: 9;
}
.upload-logo {
  max-width: 42%;
}
.upload-card {
  min-height: 287px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .clear-upload {
    color: #2196f3;
    padding: 7px 0;
  }
}
.img-upload {
  max-height: 160px;
  width: auto;
  margin: 0 auto;
  display: block;
}
@media (max-width: 767px) {
  .register {
    padding: 0;
  }
  .register-image {
    display: none;
  }
  .register-form {
    width: 100%;
    padding-right: 0;
  }
}
</style>
<style>
.upload-card .p-card-header {
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>