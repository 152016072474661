import axios from '../plugins/axios';
import {
  SET_LOGGED_IN,
  GET_LOGGED_IN,
  LOG_IN,
  LOG_OUT,
  UPDATE_USER,
  SOCIAL_LOG_IN,
  GET_USERDATA,
  SET_USERDATA,
  FETCH_PERMISSIONS,
  UPDATE_PERMISSIONS,
  GET_PERMISSIONS,
  SET_DOCUMENTS
} from '../store/types';
import { COOKIE_EXPIRATION_TIME } from './constant';

const initialLoggedIn = localStorage.getItem('loggedIn');

const state = {
  loggedIn: initialLoggedIn ? initialLoggedIn : null,
  permissions: [],
};

const actions = {
  async [LOG_IN](context, { username, password }) {
    try {
      const res = await axios.post(`user/login`, { username, password });

      const login = res.data.data.items[0].login;
      
      console.log('LOGIN ', login);

      if(login)
      {
        console.log(" login OK ");
        //localStorage.setItem("userData",);
        localStorage.setItem("userData", JSON.stringify(res.data.data.items[0]));
        localStorage.setItem('loggedIn', true);
        const data = res.data.data.items[0];
        context.commit(SET_USERDATA, data, { root: true })
        context.commit(SET_DOCUMENTS, [])
        context.dispatch(FETCH_PERMISSIONS, data.id)
        localStorage.setItem(
          'expiration',
          new Date().getTime() + COOKIE_EXPIRATION_TIME * 1000
        );
        context.commit(SET_LOGGED_IN, true);
      } else {
        console.log(" login NOT OK ");
        context.commit(SET_LOGGED_IN, false);
      }

    }catch(err){
      console.log(err)
    }

  },

  async [FETCH_PERMISSIONS]({ commit }, id) {
    try {
      const res = await axios.get(`/user/permissions/${id}`);
      const data = res.data.data.items;
      commit(UPDATE_PERMISSIONS, data)
    } catch (err) {
      console.log(err)
    }
  },

  async [SOCIAL_LOG_IN]({ commit }, payload) {
    localStorage.setItem('loggedIn', true);

    commit(SET_USERDATA, payload, { root: true })
    localStorage.setItem(
      'expiration',
      new Date().getTime() + COOKIE_EXPIRATION_TIME * 1000
    );
    commit(SET_LOGGED_IN, true);
  },

  async [UPDATE_USER]({ commit, getters }, payload) {
    const userId = getters[GET_USERDATA].id;

    let formData = new FormData();

    for (let key in payload) {
      formData.append(key, payload[key])
    }

    try {
      const res = await axios.put(`user/${userId}`, formData);
      const resObj = res.data.data.items[0];

      commit(SET_USERDATA, resObj, { root: true })

    } catch (err) {
      console.log('[update error]', err);
    }

  },

  [LOG_OUT](context) {
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('expiration');
    localStorage.removeItem('userData');
    context.commit(SET_LOGGED_IN);
  }
};

const mutations = {
  [SET_LOGGED_IN](state) {
    const initLoggedIn = localStorage.getItem('loggedIn');
    state.loggedIn = initLoggedIn ? initLoggedIn : null;
  },
  [UPDATE_PERMISSIONS](state, data) {
    state.permissions = data;
  }
};

const getters = {
  [GET_LOGGED_IN]: (state) => state.loggedIn,
  [GET_PERMISSIONS]: (state) => state.permissions
};

export default {
  state,
  actions,
  mutations,
  getters
};
