<template>
  <Avatar class="avatar" :image="data.img" size="xlarge" shape="circle" v-if="data">
    {{ withoutAvatar }}
    <div class="photo-edit" v-if="edit" >
        <img src="/assets/images/photo-edit.svg" alt="" />
    </div>
    <input class="input-file" name="myFile" type="file" @change="changeLogo">
  </Avatar>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    }
  },
  setup(props, {emit}) {
    const withoutAvatar = computed(() => {
      const data = props.data;
      let name = null;
      if (!data.img) {
        const firstName = data.first_name ? data.first_name[0] : '';
        const lastName = data.last_name ? data.last_name[0] : '';
        name = firstName + lastName;
      }
      return name;
    });

    function changeLogo(e) {
     emit('changeLogo', e.target.files[0])
    }

    return {
      withoutAvatar,
      changeLogo
    };
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  position: relative;
  color: #000;
  overflow: hidden;
  background: lightgray;
  .photo-edit {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.6);
    width: 100%;
    img {
        width: 15px;
        height: 15px;
        position: relative;
        top: -1px;
    }
  }
  .input-file {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
  }
}
</style>