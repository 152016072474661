<template>
  <div class="tree-parents p-mt-3" v-if="items">
    <div
      class="item p-mb-3"
      :class="{ active: index + 1 == currentIndex }"
      v-for="(item, index) in items"
      :key="item.id"
      @click="currentIndex = index + 1"
    >
      <div class="icon">
        <i class="pi pi-apple"></i>
      </div>
      <span>{{ item.name }}</span>
    </div>
  </div>
  <div class="dropdown-select">
    <transition name="slide">
      <div class="dropdown-options">
        <TreeCheckedOnlyChildren
          :treeValue="currentParent"
          :checked="checked"
          @selectedItems="$emit('selectedItems', $event)"
        >
        </TreeCheckedOnlyChildren>
      </div>
    </transition>
  </div>
</template>
<script>
import { ref, computed } from 'vue';
import TreeCheckedOnlyChildren from './TreeCheckedOnlyChildren.vue';
import { GET_CATEGORY_PARENTS } from '@/store/types';
import { useStore } from 'vuex';
export default {
  name: 'TreeSelect',
  props: {
    label: {
      type: String,
      default: 'label'
    },
    name: String,
    checked: {
      type: Object
    },
    defaultVal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TreeCheckedOnlyChildren
  },
  setup(props, { emit }) {
    const store = useStore();
    const items = computed(() => store.getters[GET_CATEGORY_PARENTS]);
    const currentIndex = ref(0);

    if (props.defaultVal) {
      currentIndex.value = 1;
    }

    const currentParent = computed(() => {
      let item = null;
      if (Array.isArray(items.value) && currentIndex.value > 0) {
        item = items.value[currentIndex.value - 1].children;
      }
      return item;
    });

    return {
      items,
      currentIndex,
      currentParent
    };
  }
};
</script>
<style lang="scss" scoped>
.tree-parents {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 350px;
  margin: 0 auto;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95px;
    cursor: pointer;
    text-align: center;
    &.active {
      color: rgba(60, 179, 113, 0.4);
      span {
        color: #000;
      }
    }
    span {
      margin-top: 0.25rem;
    }
    .icon {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
}

.dropdown-select {
  position: relative;
}
.trigger-container {
  border: 1px solid #ced4da;
  padding: 0.5rem 0.5rem;
  border-radius: 3px;
  &:active {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.2rem #a6d5fa;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
    border-color: #2196f3;
  }
  &:hover {
    border-color: #2196f3;
  }
}
.dropdown-select-trigger {
  display: flex;
  align-items: center;
  position: relative;
  height: 26px;
  padding-right: 28px;
  justify-content: space-between;
  cursor: pointer;
}
.dropdown-label {
  padding: 0;
  margin: 0;
  margin-right: 5px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 180%;
  text-align: right;
  letter-spacing: 0.01em;
  color: #2d3748;
}

.dropdown-label::after {
  position: absolute;
  display: block;
  content: '';
  width: 8px;
  height: 8px;
  top: 50%;
  right: 0;
  margin-top: -2px;
  border-bottom: 1px solid #2d3748;
  border-right: 1px solid #2d3748;
  -webkit-transform: rotate(45deg) translateY(-50%);
  -ms-transform: rotate(45deg) translateY(-50%);
  transform: rotate(45deg) translateY(-50%);
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
.dropdown-label.open::after {
  margin-top: 3px;
  -webkit-transform: rotate(-135deg) translateY(-50%);
  -ms-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
}
.dropdown-options ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #283441;
  text-transform: capitalize;
  padding: 0;
  margin-bottom: 15px;
  cursor: pointer;
}
.dropdown-options ul:last-child {
  margin-bottom: 0px;
}
.dropdown-options ul li.active {
  font-weight: bold;
}

.dropdown-options {
  // position: absolute;
  width: 100%;
  margin-top: 10px;
  // z-index: 9;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.slide-enter-to,
.slide-leave {
  max-height: 300px;
  overflow: hidden;
  margin-top: 10px;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
  margin-top: 0px;
}
</style>
<style>
.dropdown-options .p-tree {
  padding: 0;
}
</style>
