<template>
  <div class="col-12">
    <Card>
      <template #content>
        <div class="col-12 plan-types">
          <span
            @click="is_year = true"
            :class="{ active: is_year }"
            class="plan-type"
          >
            Annually
          </span>
          <span
            @click="is_year = false"
            :class="{ active: !is_year }"
            class="plan-type"
          >
            Monthly
          </span>
          <br />
        </div>
        <div class="plan-list">
          <div class="plan plan-items">
            <div class="plan-header"></div>
            <div class="plan-body">
              <ul>
                <li v-for="(item, i) in items" :key="i">
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>

          <div
            class="plan plan-features"
            v-for="(plan, name) in plans"
            :key="name"
          >
            <div class="plan-header">
              <h3>{{ name }}</h3>
              <span class="price" v-if="plan.year">
                ${{ is_year ? plan.year : plan.month }}
              </span>
              <span class="price-postfix" v-if="plan.year">
                {{ is_year ? '/year' : '/ month' }}
              </span>
            </div>
            <div class="plan-body">
              <ul>
                <li
                  v-for="(item, i) in items"
                  :key="i"
                  v-html="getItemValue(item, name)"
                ></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 p-mt-5 p-text-center">
          <router-link to="/public/register">
            <Button
              label="Start Free trial now"
              icon="pi pi-send"
              class="p-button-rounded p-button-danger p-button-lg"
            />
          </router-link>
          <div class="p-mt-4">
            <p class="p-text-bold">Get 14-day Free trial</p>
            <p class="p-mt-2">includes most features and 50 bookings</p>
            <p class="cursive p-mt-3">No credit card needed.</p>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { FETCH_SUBSCRIPTIONS, GET_SUBSCRIPTIONS } from '../../store/types';
export default {
  components: {},
  setup() {
    const store = useStore();
    const is_year = ref(false);
    const plans = computed(() => {
      let plans = {};
      const subscriptions = store.getters[GET_SUBSCRIPTIONS];
      subscriptions.forEach((el) => {
        plans[el.name] = {
          year: el.year_price == 0 ? null : el.year_price,
          month: el.month_price == 0 ? null : el.month_price
        };
      });
      return plans;
    });
    const items = computed(() => {
      let items = {};
      const subscriptions = store.getters[GET_SUBSCRIPTIONS];
      subscriptions.forEach((el) => {
        for (const [name, item] of Object.entries(el.items)) {
          if (typeof items[name] == 'undefined') {
            items[name] = {
              name: name
            };
          }
          items[name][el.name] = item;
        }
      });
      return items;
    });
    store.dispatch(FETCH_SUBSCRIPTIONS);

    function getItemValue(itemPlans, plan) {
      console.log(itemPlans, plan);
      if (typeof itemPlans[plan] == 'undefined') {
        return '_';
      }
      const value = itemPlans[plan][is_year.value ? 'year' : 'month'];
      if (typeof value == 'boolean' && value) {
        return '<i class="pi pi-check" style="color: #80dc81;font-weight:800;"></i>';
      } else if (typeof value == 'boolean') {
        return '<i class="pi pi-times" style="color: #ef3558;font-weight:800;"></i>';
      }
      return value;
    }
    return {
      plans,
      items,
      is_year,
      getItemValue
    };
  }
};
</script>
<style scoped>
.plan-list {
  display: flex;
  width: 100%;
}
.plan {
  width: 20%;
  text-align: center;
}
.plan-header {
  height: 70px;
}
.plan-body ul {
  list-style-type: none;
  padding: 0;
}
.plan-body li {
  padding: 12px 20px;
  font-weight: 500;
}
.plan-list .plan:nth-child(2n) {
  background: rgba(241, 246, 255, 0.5);
}
.plan-body li:nth-child(odd) {
  background: rgba(241, 246, 255, 0.5);
}
.plan.plan-features:hover {
  background: #fff;
  border-radius: 20px;
  -webkit-box-shadow: 2px 4px 15px 3px rgb(86 154 196 / 15%);
  box-shadow: 2px 4px 15px 3px rgb(86 154 196 / 15%);
}
.plan.plan-items ul {
  text-align: left;
}
span.plan-type {
  cursor: pointer;
  color: #aaa;
  margin-right: 10px;
  font-size: 16px;
}
span.plan-type.active {
  color: #666;
  text-decoration: underline;
}
.plan-types {
  text-align: right;
  padding: 25px;
}
.plan-header h3 {
  color: #253246;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}
.plan-header .price {
  color: #06adef;
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
}
.plan-header .price-postfix {
  color: #253246;
  padding-left: 5px;
}
.plan-body li i.pi {
  font-size: 16px;
  font-weight: 600;
}
.cursive {
  display: block;
  color: #06adef;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  line-height: 1.4;
  letter-spacing: 0.3px;
}
</style>