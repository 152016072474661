<template>
  <div class="phone-input">
    <InputText
      type="number"
      :placeholder="placeholder"
      v-model="phoneNumber"
      @change="onChange"
      class="main-input"
      :required="required"
      @blur="$emit('onBlur')"
    />
    <Dropdown
      v-model="prefixCode"
      :options="codes"
      optionLabel="name"
      class="prefix"
      @change="onChange(), $emit('onBlur')"
    >
      <template #value="slotProps">
        <div
          class="country-item country-item-value"
          v-if="slotProps.value && slotProps.value.code_iso2"
        >
          <!-- <img src="../../assets/images/flag_placeholder.png" :class="'flag flag-' + slotProps.value.code.toLowerCase()" /> -->
          <span
            :class="'flag flag-' + slotProps.value.code_iso2.toLowerCase()"
          />
          <div>{{ slotProps.value.name }}</div>
        </div>
        <span v-else class="country-item country-item-value">
          <span class="flag flag-fr" />
          <div>{{ prefixCode }} France</div></span
        >
      </template>
      <template #option="slotProps">
        <div class="country-item" >
          <span
            :class="'flag flag-' + slotProps.option.code_iso2.toLowerCase()"
          />
          <div>
            {{ slotProps.option.name }} +{{ slotProps.option.prefix_phone }}
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>
<script>
import { ref } from 'vue';
import { countries } from '../../store/data/countries';
import { number } from 'yup/lib/locale';
export default {
  props: {
    modelValue: String,
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    item: {
      type: String,
    },
    getNumber: {
      type: Number
    }
  },
  watch:{
    getNumber(){
      if(this.getNumber !== undefined && this.getNumber !== null){
        this.phoneNumber = parseInt((this.getNumber).toString().substring(this.prefixCode.length));
      }
    }
  },
  setup(props, { emit }) {
    const codes = ref(countries);
    const phoneNumber = ref();
    const prefixCode = ref('33');
    const wholeNumber = ref();
    const modelValueCommit = ref();

    wholeNumber.value = props.modelValue;

    try {
      if (props.modelValue !== undefined && props.modelValue !== null) {
        const phoneSplit = props.modelValue.split(' ');
        prefixCode.value = phoneSplit[0];
        phoneNumber.value = parseInt(props.modelValue.substring(prefixCode.value.length));
        
      } else {
        phoneNumber.value = '';
        wholeNumber.value = prefixCode.value + ' ' + phoneNumber.value;
      }
    } catch {
      console.log('phone number error');
    }

    function onChange() {
      const prefix = prefixCode.value.prefix_phone || 33;
      const number = phoneNumber.value || '';
      wholeNumber.value = prefix + ' ' + number;
      modelValueCommit.value = prefix + ' ' + number;
      emit('update:modelValue', modelValueCommit);
      emit('phone', modelValueCommit.value);
      emit('phoneNumber', number);
      emit('prefix', prefix)
    }

    return {
      codes,
      prefixCode,
      phoneNumber,
      wholeNumber,
      onChange,
    };
  }
};
</script>

<style lang="scss" scoped>
.phone-input {
  position: relative;
}
input {
  display: flex;
  align-items: center;
}
input:focus {
  outline: none;
}
.main-input {
  width: 100%;
}
.prefix {
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  width: 160px;
  border: none;
}

select:focus {
  outline: none;
}

.country-item {
  display: flex;
  align-items: center;

  span.flag {
    width: 18px;
    height: 12px;
  }
}
.country-item-value {
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.5rem;
  color: #000000;
}
.p-fluid .p-dropdown .p-dropdown-label {
  padding-left: 0;
}
.phone-input {
  input {
    padding-left: 180px;
    text-align: left;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
