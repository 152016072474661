<template>
  <div class="btn-slot" :style="{width: width ? width : ''}" @click="deleteThis">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useConfirm } from 'primevue/useconfirm';
import useI18n from '@/plugins/useI18n';

export default defineComponent({
  props: {
    width: String,
  },
  setup(props, { emit }) {
    const confirm = useConfirm();

    const { i18n } = useI18n();

    const message = i18n.$t('Do you want to delete this?');

    function deleteThis(event) {
      confirm.require({
        target: event.currentTarget,
        message: message,
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: () => {
          emit('accept');
        },
        reject: () => {
          emit('reject');
          confirm.close();
        }
      });
    }

    return { deleteThis };
  }
});
</script>

<style scoped>
.btn-slot {
  display: flex;
}

</style>
