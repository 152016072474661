<template>
  <div class="calendar-sidebar">
    <Breadcrumb
      :home="breadcrumbHome"
      :model="breadcrumbItems"
      class="p-mb-3"
    />
    <div class="p-mb-2">
      <Button @click="onAddEvent">{{ i18n.$t('Add Event') }}</Button>
    </div>
    <div class="calendar-datepicker cal-sidebar">
      <div
        class="tui-datepicker-input tui-datetime-input tui-has-focus"
        style="display: none"
      >
        <input type="text" id="tui-date-picker-target" aria-label="Date-Time" />
        <span class="tui-ico-date"></span>
      </div>
      <div
        id="tui-date-picker-container"
        style="margin-top: -1px; position: relative"
      ></div>
    </div>
    <!-- <div class="filter-services"> 
      <p class="p-mr-3">Services</p>

      <div class="flex required-field p-mt-3">
        <p class="p-mr-3">Required Field</p>
        <Checkbox v-model="currentQuestion.mandatory" :binary="true" />
      </div>
    </div> -->
    <div class="setting-btn">
      <div class="services p-mb-4" v-if="all_services.length > 0">
        <h5>{{ i18n.$t('Filter categories') }}:</h5>
        <div class="p-mb-3" v-for="el in all_services" :key="el._id">
          <Checkbox
            class="p-mr-2"
            :id="el._id"
            :name="el.name"
            :value="el._id"
            v-model="selectedServices"
            @change="updateSelected"
          />
          <label :style="{ color: `#${el.color}` }" :for="el._id">{{
            el.name
          }}</label>
        </div>
        <div class="p-mb-3">
          <Checkbox
            class="p-mr-2"
            id="all-check"
            name="All"
            :binary="true"
            v-model="allChecked"
            @change="updateSelected('all-service')"
          />
          <label for="all-check">All</label>
        </div>
        <Button
          :label="i18n.$t('Clear')"
          class="p-button-outlined"
          @click="
            (selectedServices = []), (allChecked = false), updateSelected()
          "
        />
      </div>
      <div class="employees p-mb-4" v-if="employees.length > 0">
        <h5>{{ i18n.$t('Filter Employees') }}:</h5>
        <div class="p-mb-3" v-for="el in employees" :key="el._id">
          <Checkbox
            class="p-mr-2"
            :id="el.id"
            :name="el.first_name"
            :value="el.id"
            v-model="selectedEmployees"
            @change="updateSelected"
          />
          <label :style="{ color: `#${el.color}` }" :for="el._id">{{
            el.first_name + ' ' + el.last_name
          }}</label>
        </div>
        <div class="p-mb-3">
          <Checkbox
            class="p-mr-2"
            id="all-employee-check"
            name="AllEmployees"
            :binary="true"
            v-model="allEmployeesChecked"
            @change="updateSelected('all-employee')"
          />
          <label for="all-employee-check">All</label>
        </div>
        <Button
          :label="i18n.$t('Clear')"
          class="p-button-outlined"
          @click="
            (selectedEmployees = []),
              (allEmployeesChecked = false),
              updateSelected()
          "
        />
      </div>
      <Button @click="$router.push({ path: `/profile/settings` })">
        {{ i18n.$t('Settings') }}
      </Button>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import DatePicker from 'tui-date-picker';
import { emitter } from '../../main';
import useI18n from '@/plugins/useI18n';
import { GET_LANGUAGE, GET_SERVICES, GET_EMPLOYEES } from '../../store/types';
import { useRoute } from 'vue-router';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [{ label: 'My Calendar', to: '/events/tui-calendar' }]
  }),
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const route = useRoute();
    const lang = computed(() => store.getters[GET_LANGUAGE]);
    onMounted(() => {
      const datePickerContainer = document.getElementById(
        'tui-date-picker-container'
      );
      const datePickerTarget = document.getElementById(
        'tui-date-picker-target'
      );
      if (lang.value == 'fr') {
        DatePicker.localeTexts['lang'] = {
          titles: {
            // days
            DD: [
              'Dimanche',
              'Lundi',
              'Mardi',
              'Mercredi',
              'Jeudi',
              'Vendredi',
              'Samedi'
            ],
            // daysShort
            D: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
            // months
            MMMM: [
              'Janvier',
              'Février',
              'Mars',
              'Avril',
              'Mai',
              'Juin',
              'Juillet',
              'Août',
              'Septembre',
              'Octobre',
              'Novembre',
              'Décembre'
            ],
            // monthsShort
            MMM: [
              'Jan',
              'fév',
              'Mar',
              'avr',
              'Mai',
              'Juin',
              'juil',
              'Août',
              'Sep',
              'Oct',
              'Nov',
              'déc'
            ]
          },
          titleFormat: 'MMMM yyyy',
          todayFormat: 'D, MMMM dd, yyyy'
        };
      } else {
        DatePicker.localeTexts['lang'] = {
          titles: {
            // days
            DD: [
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday'
            ],
            // daysShort
            D: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            // months
            MMMM: [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December'
            ],
            // monthsShort
            MMM: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec'
            ]
          },
          todayFormat: 'D, MMMM dd, yyyy',
          titleFormat: 'MMM yyyy',
          date: 'Date',
          time: 'Time'
        };
      }

      const datePickerInstance = new DatePicker(datePickerContainer, {
        input: {
          element: datePickerTarget
        },
        showAlways: true,
        language: 'lang'
      });

      datePickerInstance.on('change', (e) => {
        emitter.emit('calendar-date-change', datePickerInstance.getDate());
      });
    });

    const all_services = computed(() => store.getters[GET_SERVICES]);
    const employees = computed(() => store.getters[GET_EMPLOYEES]);

    const selectedServices = ref([]);
    const selectedEmployees = ref([]);

    firstLoadFilterServices();

    function firstLoadFilterServices() {
      all_services.value.forEach((el) => selectedServices.value.push(el._id));
    }
    firstLoadFilterEmployees();

    function firstLoadFilterEmployees() {
      employees.value.forEach((el) => selectedEmployees.value.push(el.id));
    }

    const allChecked = ref(true);
    const allEmployeesChecked = ref(true);

    function updateSelected(el) {
      const addAllS =
        selectedServices.value.length == all_services.value.length;
      const addAllE = selectedEmployees.value.length == employees.value.length;
      allChecked.value = false;
      allEmployeesChecked.value = false;
      if (el == 'all-service') {
        if (!addAllS) {
          selectedServices.value = [];
          firstLoadFilterServices();
        }
        allChecked.value = true;
      } else if (addAllS) {
        allChecked.value = true;
      }
      if (el == 'all-employee') {
        if (!addAllE) {
          selectedEmployees.value = [];
          firstLoadFilterEmployees();
        }
        allEmployeesChecked.value = true;
      } else if (addAllE) {
        allEmployeesChecked.value = true;
      }
      emitter.emit('updateFilters', {
        service: selectedServices.value,
        employee: selectedEmployees.value
      });
    }

    if (typeof route.query.employee != 'undefined') {
      selectedEmployees.value = [route.query.employee];
      allEmployeesChecked.value = false;
    }

    function onAddEvent() {
      emitter.emit('add-tui-event');
    }
    return {
      onAddEvent,
      i18n,
      all_services,
      selectedServices,
      updateSelected,
      allChecked,
      employees,
      selectedEmployees,
      allEmployeesChecked
    };
  }
};
</script>

<style lang="scss" scoped>
.calendar-sidebar {
  width: 320px;
  min-width: 300px;
  height: 100vh;
  padding: 20px 10px;
}
@media (max-width: 1050px) {
  .calendar-sidebar {
    background: linear-gradient(to bottom, #f3f4f9 0%, #d7dbe8 100%);
  }
}
.calendar-datepicker {
  margin: 0 auto;
}
.menu-icon {
  cursor: pointer;
}
.setting-btn {
  margin-top: 305px;
}
</style>
<style>
.tui-calendar-header .tui-calendar-header-info {
  display: none;
}
.tui-calendar,
.tui-datepicker,
.tui-calendar-btn {
  background: none;
  border: none;
}
.tui-calendar th,
.tui-calendar td,
.tui-calendar .tui-calendar-body-header th {
  color: #333;
}
.tui-datepicker .tui-is-selectable.tui-is-selected {
  border-radius: 50%;
}

.tui-calendar-header-inner .tui-calendar-btn::after {
  background: url('/assets/layout/images/right-arrow.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center !important;
}
.tui-calendar .tui-calendar-btn-prev-month:after,
.tui-calendar.tui-calendar-month .tui-calendar-btn-prev-year:after,
.tui-calendar .tui-calendar-btn-next-month:after,
.tui-calendar.tui-calendar-month .tui-calendar-btn-next-year:after,
.tui-calendar.tui-calendar-year .tui-calendar-btn-next-year:after,
.tui-calendar.tui-calendar-year .tui-calendar-btn-prev-year:after {
  width: 18px;
  height: 18px;
  /* top: 50%;
  transform: translateX(50%);
  right: 50%;
  margin-right: 0; */
}
.tui-calendar .tui-calendar-btn-prev-month:after,
.tui-calendar.tui-calendar-month .tui-calendar-btn-prev-year:after,
.tui-calendar.tui-calendar-year .tui-calendar-btn-prev-year:after {
  left: 32%;
}
.tui-calendar .tui-calendar-btn-next-month:after,
.tui-calendar.tui-calendar-month .tui-calendar-btn-next-year:after,
.tui-calendar.tui-calendar-year .tui-calendar-btn-next-year:after {
  right: 32%;
}
.tui-calendar-header-inner .tui-calendar-btn {
  top: 0;
}
.tui-calendar-header-inner .tui-calendar-btn:focus {
  outline: none;
}
.tui-calendar-header-inner .tui-calendar-btn:first-child {
  left: auto;
  right: 0;
  transform: rotate(-90deg);
}
.tui-calendar-header-inner .tui-calendar-btn:last-child {
  transform: rotate(90deg);
  right: 20px;
}
.tui-calendar .tui-calendar-title {
  position: absolute;
  left: 0;
  padding-left: 35px;
}
.tui-calendar .tui-calendar-title:before {
  overflow: hidden;
  position: absolute;
  top: 50%;
  line-height: 400px;
  left: 5px;
  content: '';
  width: 18px;
  height: 18px;
  transform: translateY(-50%) rotate(-90deg);
  background: url('/assets/images/back.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center !important;
}
.layout-calendar.layout-sidebar {
  background: transparent !important;
}
.cal-sidebar .tui-calendar {
  width: 240px;
}
.tui-calendar td {
  height: 34px;
}
</style>
