import axios from '../plugins/axios';
import { FETCH_SUBSCRIPTIONS, SET_SUBSCRIPTIONS, GET_SUBSCRIPTIONS } from '../store/types';

const state = {
  subscriptions: []
};

const actions = {
  async [FETCH_SUBSCRIPTIONS](context) {
    const res = await axios.get(`subscription`);
    context.commit(SET_SUBSCRIPTIONS, res.data.data.items);
  }
};

const mutations = {
  [SET_SUBSCRIPTIONS](state, payload) {
    state.subscriptions = payload;
  }
};

const getters = {
  [GET_SUBSCRIPTIONS]: (state) => state.subscriptions
};

export default {
  state,
  actions,
  mutations,
  getters
};
