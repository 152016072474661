export const MENU_ITEMS = [{
    label: 'Dashboard',
    icon: 'pi pi-fw pi-home',
    to: '/',
    permission: 'none'
  },
  {
    label: 'Get Started',
    to: '/get-started',
    permission: 'none'
  },
  {
    label: 'Calendar',
    icon: 'pi pi-fw pi-calendar',
    to: '/events/tui-calendar',
    permission: 'none',
  },
  {
    label: 'Notes',
    to: '/note',
    permission: 'v1.note.index',
  },
  {
    label: 'Clients',
    to: '/contacts?_type=contacts',
    permission: 'none',
  },
  {
    label: 'Subscribers',
    to: '/contacts?_type=subscribers',
    permission: 'none',
  },
  {
    label: 'Groups',
    to: '/groups/list',
    permission: 'none',
  },
  {
    label: 'Services',
    to: '/service/list',
    permission: 'v1.service.index',
  },
  {
    label: 'Information',
    to: '/information/list',
    permission: 'v1.information.index',
  },
  {
    label: 'Document',
    to: '/document/list',
    permission: 'none',
  },
  {
    label: 'Employee',
    to: '/employee/list',
    permission: 'none',
  },
  {
    label: 'Agenda',
    to: '/agenda',
    permission: 'v1.agenda.index',
  },
  {
    label: 'QR code',
    to: '/qrcode',
    permission: 'none',
  }
];