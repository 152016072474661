import axios from '../plugins/axios';

import {
  FETCH_AGENDA,
  SET_AGENDA,
  GET_AGENDA
} from './types';

const state = {
  agenda: null
};

const actions = {
  async [FETCH_AGENDA](context, userId ) {
    const res = await axios.get(`agenda?owner_id=${userId}`);
    context.commit(SET_AGENDA, res.data.data.items);
  },  
};

const mutations = {
  [SET_AGENDA](state, payload) {
   // state.agenda.push(payload);
    state.agenda = payload;
  }
};

const getters = {
  [GET_AGENDA]: (state) => state.agenda
};

export default {
  state,
  actions,
  mutations,
  getters
};
