import axios from '../plugins/axios';

import {
  FETCH_GROUPS,
  SET_GROUPS,
  GET_GROUPS,
  FETCH_GROUP_BY_ID,
  SET_GROUP_CONTACTS,
  GET_GROUP_CONTACTS
} from './types';

const state = {
  groups: [],
  groupContacts: []
};

const actions = {
  async [FETCH_GROUPS](context, owner_id) {
    const res = await axios.get(`group?owner_id=${owner_id}`);
    context.commit(SET_GROUPS, res.data.data.items);
  },
  async [FETCH_GROUP_BY_ID](context, groupId) {
    const res = await axios.get(`group/${groupId}`);
    context.commit(SET_GROUP_CONTACTS, res.data.data.__contacts_id);
  }
};

const mutations = {
  [SET_GROUPS](state, payload) {
    state.groups = payload;
  },
  [SET_GROUP_CONTACTS](state, payload) {
    state.groupContacts = payload;
  }
};

const getters = {
  [GET_GROUPS]: (state) => state.groups,
  [GET_GROUP_CONTACTS]: (state) => state.groupContacts
};

export default {
  state,
  actions,
  mutations,
  getters
};
