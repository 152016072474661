export default class RDVModel {
  constructor(item) {
    this.service = item && item.service ? item.service : null;
    this.client = item && item.client ? item.client : null;
    this.date = item && item.date ? item.date : { start: null, end: null };
    this.description = item && item.description ? item.description : null;
  }

  toJson() {
    return JSON.parse(JSON.stringify(this));
  }
}
