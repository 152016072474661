//ui
export const SET_UI_NOTIFICATION = 'setUINotification';
export const GET_UI_NOTIFICATION_STATE = 'getUINotificationState';
export const GET_UI_NOTIFICATION_CONTENT = 'getUINotificationContent';
export const SET_UI_LOADING = 'setLoading';
export const GET_UI_LOADING = 'getLoading';
//auth
export const SET_LOGGED_IN = 'setLoggedIn';
export const GET_LOGGED_IN = 'getLoggedIn';
export const LOG_IN = 'logIn';
export const LOG_OUT = 'logOut';
export const UPDATE_USER = 'updateUser';

//i18n
export const SET_LANGUAGE = 'setLanguage';
export const GET_LANGUAGE = 'getLanguage';

//Agency
export const FETCH_AGENCY = 'fetchAgency';
export const UPDATE_AGENCY = 'updateAgency';
export const SET_AGENCY_MODEL = 'setAgencyModel';
export const GET_AGENCY_MODEL = 'getAgencyModel';
export const FETCH_ALL_CATEGORIES = 'fecthCategories';
export const UPDATE_CATEGORIES = 'updateCategories';
export const SET_ALL_CATEGORIES = 'setAllCategories';
export const GET_ALL_CATEGORIES = 'getAllCategories';
export const GET_SELECTED_CATEGORIES = 'getSelectedCategories';
export const GET_CATEGORY_TREE = 'getCategoryTree';
export const GET_SELECTED_CARTEGORY_TREE_VALUE =
  'getSelectedCartegoryTreeValue';

//Category parents 
export const FETCH_CATEGORY_PARENTS = 'fetchCategoryParents';
export const GET_CATEGORY_PARENTS = 'getCategoryParents';
export const SET_CATEGORY_PARENTS = 'setCategoryParents';

export const SET_SERVICES = 'setServices';
export const FETCH_SERVICES = 'fetchServices';
export const FETCH_SERVICE = 'fetchService';
export const SET_SERVICE = 'setService';
export const CREATE_SERVICE = 'createService';
export const EDIT_SERVICE = 'editService';
export const GET_SERVICES = 'getServices';
export const GET_SERVICE_MODEL = 'getServiceModel';
export const FETCH_ALL_SERVICES = 'fetchAllServices';

export const FETCH_TREE_CHILD_CATEGORIES = 'fetchTreeChildrenCategories';
export const SET_TREE_CHILD_CATEGORIES = 'setTreeChildrenCategories';
export const GET_TREE_CHILD_CATEGORIES = 'getTreeChildrenCategories';

//RDV
export const SET_RDV_MODEL = 'setRDVModel';
export const GET_RDV_MODEL = 'getRDVModel';

//Documents
export const FETCH_DOCUMENTS = 'fetchDocuments';
export const SET_DOCUMENTS = 'setDocuments';
export const GET_DOCUMENTS = 'getDocuments';

//Subscription
export const FETCH_SUBSCRIPTIONS = 'fetchSubscription';
export const SET_SUBSCRIPTIONS = 'setSubscription';
export const GET_SUBSCRIPTIONS = 'getSubscription';

//contacts
export const FETCH_CONTACTS = 'fetchContacts';
export const SET_CONTACTS = 'setContacts';
export const GET_CONTACTS = 'getContacts';
export const SET_SELECTED_CONTACTS = 'setSelectedContacts';
export const GET_SELECTED_CONTACTS = 'getSelectedContacts';

//groups
export const FETCH_GROUPS = 'fetchGroups';
export const SET_GROUPS = 'setGroups';
export const GET_GROUPS = 'getGroups';
export const FETCH_GROUP_BY_ID = 'fetchGroupById';
export const SET_GROUP_CONTACTS = 'setGroupContacts';
export const GET_GROUP_CONTACTS = 'getGroupContacts';

//events
export const FETCH_EVENTS = 'fetchEvents';
export const SET_EVENTS = 'setEvents';
export const GET_EVENTS = 'getEvents';
export const SET_ALL_EVENT = 'setAllEvents';
export const SET_EVENT_EVENT = 'setEventEvents';
export const SET_TASK_EVENT = 'settTaskEvents';
export const SET_RDV_EVENT = 'setRdvEvents';
export const GET_ALL_EVENTS = 'getAllEvents';
export const GET_CALENDAR_EVENTS = 'getCalendarEvents';
export const FETCH_EVENT_BY_ID = 'fetchEventById';
export const SET_ADD_EVENT = 'addEvent';
export const SET_REPORTING = 'setReporting';
export const GET_REPORTING = 'getReporting';
export const FETCH_INFORMATION_EVENTS = 'fetchInformationEvents';
//notes
export const FETCH_NOTES = 'fetchNotes';
export const SET_NOTES = 'setNotes';
export const GET_NOTES = 'getNotes';
export const FETCH_NOTE_BY_ID = 'fetchNoteById';
export const SET_ADD_NOTE = 'addNote';

//Employee
export const FETCH_EMPLOYEES = 'fectchEmployees';
export const SET_EMPLOYEES = 'setEmployees';
export const GET_EMPLOYEES = 'getEmployees';
export const SET_SELECTED_EMPLOYEES = 'setSelectedEmployees';
export const GET_SELECTED_EMPLOYEES = 'getSelectedEmployees';

//Information
export const FETCH_INFORMATIONS = 'fetchInformation';
export const SET_INFORMATIONS = 'setInformation';
export const GET_INFORMATIONS = 'getInformation';
export const SET_INFORMATION_2_NOTIFICATION = 'setInformation2Notification';
export const GET_INFORMATION_2_NOTIFICATION = 'getInformation2Notification';
export const SET_SELECTED_GROUPS_2_NOTIFICATION =
  'setSelectedGroups2Notification';
export const GET_SELECTED_GROUPS_2_NOTIFICATION =
  'getSelectedGroups2Notification';
export const FETCH_INFORMATION_NOTIFICATIONS = 'fetchInformationNotifications';
export const SET_INFORMATION_NOTIFICATIONS = 'setInformationNotifications';
export const GET_INFORMATION_NOTIFICATIONS = 'getInformationNotifications';

//QRCode
export const FETCH_QRCODES = 'fetchQRCodes';
export const SET_QRCODES = 'setQRCodes';
export const GET_QRCODES = 'getQRCodes';

//social login
export const SOCIAL_LOG_IN = 'socialLogIn';
export const SET_AGENDA = "setAgenda";
export const GET_AGENDA = "getAgenda";
export const FETCH_AGENDA = "fetchAgenda";

// Category Services
export const SET_CATEGORY_SERVICE = "setCategoryService";
export const GET_CATEGORY_SERVICE = "getCategoryService";
export const FETCH_CATEGORY_SERVICE = "fetchCategoryService";

// LocalStorage
export const GET_USERDATA = "getUserData";
export const SET_USERDATA = "setUserData";
export const CLEAR_USERDATA = 'clearUserData';
//Brand 
export const GET_BRANDS = "getBrands";
export const FETCH_BRANDS = "fetchBrands";
export const SET_BRANDS = "setBrands";
//Permossions
export const FETCH_PERMISSIONS = 'fetchPermissions';
export const UPDATE_PERMISSIONS = 'updatePermissions';
export const GET_PERMISSIONS = 'getPermissions';