import axios from '../plugins/axios';
import {
  addMinutesToDate
} from '../plugins/utility';
import _ from 'lodash';

import {
  FETCH_EVENTS,
  SET_EVENTS,
  GET_EVENTS,
  GET_ALL_EVENTS,
  GET_CALENDAR_EVENTS,
  FETCH_EVENT_BY_ID,
  SET_ADD_EVENT,
  SET_ALL_EVENT,
  FETCH_INFORMATION_EVENTS,
  SET_REPORTING,
  GET_REPORTING
} from './types';
import moment from 'moment';

const state = {
  events: [],
  allEvents: null,
  reporting: null
};

const actions = {

  async [FETCH_EVENTS](context, {
    userId,
    type
  }) {
    const res = await axios.get(`event?owner_id=${userId}&_type=${type}`);
    context.commit(SET_EVENTS, res.data.data.items);
  },

  async [FETCH_INFORMATION_EVENTS](context, {
    userId
  }) {
    const res = await axios.get(`event?owner_id=${userId}`);
    context.commit(SET_ALL_EVENT, res.data.data.items);
  },
  async [FETCH_EVENT_BY_ID](context, {
    eventId
  }) {
    const event = _.find(context.state.events, function (o) {
      return o._id === eventId;
    });
    return event ? event : {};
  }
};

const mutations = {
  [SET_EVENTS](state, payload) {
    state.events = payload;
  },
  [SET_ADD_EVENT](state, payload) {
    state.events.push(payload);
  },
  [SET_ALL_EVENT](state, payload) {
    state.allEvents = payload;
  },
  [SET_REPORTING](state, payload) {
    state.reporting = payload.items;
  }
};

const getters = {
  [GET_EVENTS]: (state) => state.events,
  [GET_ALL_EVENTS]: (state) => state.allEvents,
  [GET_REPORTING]: (state) => state.reporting,
  [GET_CALENDAR_EVENTS]: (state) => {
    const result = state.events.map((event) => {
      const end = addMinutesToDate(event.date, event.duration);
      const bodyHTML = `
        <div class="calendar-detail-item">
          <p>Description: <small>${event.description}</small></p>
        </div>
        <div class="calendar-detail-item">
          <p>Address: <small>${event.address}</small></p>
        </div>
        <div class="calendar-detail-item">
          <p>Description:<small>${event.description}</small></p>
        </div>
        <div class="calendar-detail-item">
          <p>Repeat: <small>${event.repeat}</small></p>
        </div>
        <div class="calendar-detail-item">
          <p>Importance: <small>${event.importance}</small></p>
        </div>
        <div class="calendar-detail-item">
          <p>Expire At: <small>${event.expires_at}</small></p>
        </div>
      `;
      return {
        id: event._id,
        calendarId: event._id,
        title: event.name,
        service_id: event.service_id,
        __employee_id: event.__employee_id,
        category: 'time',
        dueDateClass: '',
        start: moment.utc(event.date).format('YYYY-MM-DD HH:mm'),
        end: end,
        body: bodyHTML
      };
    });
    return result;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};