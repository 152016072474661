import { useI18n } from "../plugins/i18nPlugin";
import store from '@/store';
import { GET_USERDATA } from '@/store/types';

export const LANGS = [
  { name: 'fr', code: '0', label: 'Français', },
  { name: 'en', code: '1', label: 'English', }
];


export const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const currencyItems = [
  { name: 'EUR', value: 'EUR' },
  { name: 'USD', value: 'USD' },
  { name: 'GBP', value: 'GBP' }
];

export const roles = [
  { name: 'director', code: 'director' },
  { name: 'manager', code: 'manager' },
  { name: 'agent', code: 'agent' }
];

export const  hours = [
  { value: 0, name: '00' },
  { value: 1, name: '01' },
  { value: 2, name: '02' },
  { value: 3, name: '03' },
  { value: 4, name: '04' },
  { value: 5, name: '05' },
  { value: 6, name: '06' },
  { value: 7, name: '07' },
  { value: 8, name: '08' },
  { value: 9, name: '09' },
  { value: 10, name: '10' },
  { value: 11, name: '11' },
  { value: 12, name: '12' },
  { value: 13, name: '13' },
  { value: 14, name: '14' },
  { value: 15, name: '15' },
  { value: 16, name: '16' },
  { value: 17, name: '17' },
  { value: 18, name: '18' },
  { value: 19, name: '19' },
  { value: 20, name: '20' },
  { value: 21, name: '21' },
  { value: 22, name: '22' },
  { value: 23, name: '23' },
  { value: 24, name: '24' },
];

export const hoursList = [
  { name: '0 hours', value: 0 },
  { name: '1 hour', value: 1 },
  { name: '2 hours', value: 2 },
  { name: '3 hours', value: 3 },
  { name: '4 hours', value: 4 },
  { name: '5 hours', value: 5 },
  { name: '6 hours', value: 6 },
  { name: '7 hours', value: 7 },
  { name: '8 hours', value: 8 },
  { name: '9 hours', value: 9 },
  { name: '10 hours', value: 10 }
];

export const minutesList = [
  { name: '0 minutes', value: 0 },
  { name: '5 minutes', value: 5 },
  { name: '10 minutes', value: 10 },
  { name: '15 minutes', value: 15 },
  { name: '20 minutes', value: 20 },
  { name: '25 minutes', value: 25 },
  { name: '30 minutes', value: 30 },
  { name: '35 minutes', value: 35 },
  { name: '40 minutes', value: 40 },
  { name: '45 minutes', value: 45 },
  { name: '50 minutes', value: 50 },
  { name: '55 minutes', value: 55 }
];
// export const WEEK_DAYS = eng_week_days;


export const SERVICE_COLORS = ['pink', 'yellow', 'green'];


export const EVENT_TYPE = {
  rdv: 'Rdv',
  task: 'Task',
  event: 'Event'
};

export const EVENTS =  [
  { name: 'Task', key: 'Task' },
  { name: 'Event', key: 'Event' },
  { name: 'Rdv', key: 'Rdv' }
]

export const COOKIE_EXPIRATION_TIME = 1800; //1800s

export const NOTIFICATION_TYPE = {
  success: 'success',
  error: 'error',
  warn: 'warn',
  info: 'info'
};

export const SCHEDULE_TIMELINE_MARKS = [
  '00:00',
  '02:00',
  '04:00',
  '06:00',
  '08:00',
  '10:00',
  '12:00',
  '14:00',
  '16:00',
  '18:00',
  '20:00',
  '22:00',
  '24:00'
];

export const SCHEDULE_TIME_MILESTONES = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '24:00'
];
